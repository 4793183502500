import React, { Fragment, useEffect } from "react";
import dynamic from "next/dynamic";
import propTypes from "prop-types";
import { observer } from "mobx-react-lite";
import getEnvironDomain from "ati-utils/getEnvironDomain";
import CaseIcon from "ati-ui-react/unstyled/components/icons/Case";
import Pagination from "ati-ui-react/unstyled/widgets/Pagination";
import Button from "ati-ui-react/unstyled/components/Button/v3";
import declension from "ati-core-utils/declension";
import FormOutput from "../Forms/FormOutput";
import { useStore } from "../../../store/RootStore";
import { snakeToCamelObj } from "../../../utils/objectHelpers";
import PageCountSelect from "./PageCountSelect";
import ResultListControl from "./ResultListControl";
import SubscribePopup from "./SubscribePopup";

import {
  ListStyles,
  ListEmptyTitle,
  ListEmptyDescription,
  ListEmptyContent,
  ListTop,
  ListTitle,
  ListControl,
  ListBottom,
  ListBottomItem,
  ListCardsWrapper,
  ListTopWrapper,
} from "./ResultList.styles";
import SeoTenders from "../SeoTenders";
import i18nStrings from "./strings";

const TendersCard = dynamic(() => import("tenders-card"), { ssr: false });

const ResultList = ({ id, className }) => {
  const store = useStore();

  const { isDesktop } = store.mediaModule;
  const { visibleSubscribePopup, showSubscribePopup } = store.subscribePopupModule;
  const {
    account,
    hasTenderLicense,
    resultListError,
    searchResult,
    citiesForCards,
    regionsForCards,
    countriesForCards,
    tenderContactsStatuses,
    dictionariesForCards,
    boardsForCards,
    pageTake,
    onChangePageTake,
    currentPage,
    totalPages,
    onChangeCurrentPage,
    searchLoading,
    filterForCards,
    isInitialRequest,
    isAuthorized,
  } = store.globalModule;

  const { locale } = store.localeModule;

  const strings = i18nStrings[locale || "ru"];

  useEffect(() => {
    window.headerRegistartionLinkParams = `?redir=true&next=https://${getEnvironDomain()}${window.location.pathname}`;
  }, []);

  const renderTenderCards = () => {
    const dictionaries = {
      cities: citiesForCards,
      regions: regionsForCards,
      countries: countriesForCards,
      ...dictionariesForCards,
    };
    const cards = searchResult.tenders?.map(({ tender, firm, icon }, key) => {
      const isSecondary = key % 2 !== 0;
      return (
        <TendersCard
          showMobileActions
          boards={boardsForCards}
          data={tender}
          dictionaries={dictionaries}
          filter={filterForCards}
          firm={firm}
          hasTenderLicense={hasTenderLicense}
          icon={icon}
          isAuthorized={isAuthorized}
          key={tender.id}
          locale={locale}
          secondary={isSecondary}
          statuses={tenderContactsStatuses}
          user={snakeToCamelObj(account)}
        />
      );
    });

    return cards;
  };

  const renderPageCountSelect = () => (
    <PageCountSelect
      count={pageTake}
      disabled={searchLoading}
      locale={locale}
      onSelect={onChangePageTake}
    />
  );

  const renderPagination = () => {
    if (totalPages > 1) {
      return (
        <div className="pagination">
          <Pagination
            disabled={searchLoading}
            initialPage={currentPage}
            locale={locale}
            pageCount={totalPages}
            onPageChange={onChangeCurrentPage}
          />
        </div>
      );
    }
    return null;
  };

  const renderSearchTitle = () => {
    const titlePlural = declension(searchResult.count, strings.tenderFound);
    const titleText = `${searchResult.count} ${titlePlural}`;
    if (isInitialRequest) return <ListTitle>{searchResult.count} {titlePlural}</ListTitle>;
    return <ListTitle>{titleText}</ListTitle>;
  };

  const renderTenderList = () => (
    <>
      <ListTop>
        {renderSearchTitle()}
        <ListTopWrapper>
          <FormOutput />
          {isDesktop
          && (
          <ListControl>
            {renderPagination()}
          </ListControl>
          )}
        </ListTopWrapper>
      </ListTop>
      <ResultListControl />
      <ListCardsWrapper>
        {renderTenderCards()}
      </ListCardsWrapper>
      <ListBottom>
        <ListBottomItem>
          {renderPageCountSelect()}
        </ListBottomItem>
        <ListBottomItem>
          {renderPagination()}
        </ListBottomItem>
      </ListBottom>
    </>
  );

  const renderEmptyListError = () => (
    <ListEmptyContent>
      <CaseIcon
        className="empty-icon"
        fillRule="evenodd"
        height={43}
        viewBox="0 0 28 25"
        width={48}
      />
      <ListEmptyTitle>{strings.tendersNotFound}</ListEmptyTitle>
      {locale !== "en"
      && (
      <ListEmptyDescription>
        {strings.subscribeToTenders}
      </ListEmptyDescription>
      )}
      {locale !== "en"
      && (
      <Button theme="success" onClick={showSubscribePopup}>
        {strings.subcribe}
      </Button>
      )}
    </ListEmptyContent>
  );

  const renderContent = () => {
    if (resultListError) {
      return (
        <ListEmptyContent>{resultListError}</ListEmptyContent>
      );
    }

    if (searchResult.tenders?.length) {
      return renderTenderList();
    }

    if (searchResult.tenders?.length === 0) {
      return renderEmptyListError();
    }

    return null;
  };

  return (
    <ListStyles className={className} id={id}>
      {renderContent()}
      <SeoTenders locale={locale} />
      {visibleSubscribePopup && <SubscribePopup />}
    </ListStyles>
  );
};

ResultList.defaultProps = {
  id: "",
  className: "",
};

ResultList.propTypes = {
  className: propTypes.string,
  id: propTypes.string,
};

export default observer(ResultList);
