import React from "react";
import { observer } from "mobx-react-lite";
import Tooltip from "../../../Common/Tooltip";
import { useStore } from "../../../../store/RootStore";
import { urls } from "../../../../const";
import {
  FilterTabsStyles,
  FilterTabsItem,
  FilterTabsLink,
  FilterTabsItemText,
  FilterTabsList,
} from "./FilterTabs.styles";

const FilterTabs = () => {
  const store = useStore();
  const { tabs, activeTab, setActiveTab } = store.tabsModule;
  const { isAuthorized } = store.globalModule;
  const { showLoginPopup } = store.loginPopupModule;

  const getTabClassName = (tab) => {
    const active = activeTab === tab.id;
    if (tab.disabled) return "disabled";
    if (active) return "active";
    return "";
  };

  const renderTabs = () => tabs.map((tab) => {
    const classNames = getTabClassName(tab);
    const tabTooltipId = `${tab.id}-tab-tooltip`;

    if (tab.isBlueButton) {
      return (
        <FilterTabsLink href={urls.tendersAdd} key={tab.id}>
          {tab.label}
        </FilterTabsLink>
      );
    }

    const handleClickTab = (event) => {
      if (tab.disabled) {
        event.preventDefault();
        return;
      }
      if (tab.href && !isAuthorized && tab.showLoginPopup) {
        event.preventDefault();
        showLoginPopup();
        return;
      }
      if (!tab.href) {
        setActiveTab(tab.id);
      }
    };

    return (
      <FilterTabsItem
        data-tip
        className={classNames}
        data-for={tabTooltipId}
        href={tab.href}
        key={tab.id}
        onClick={handleClickTab}
      >
        <FilterTabsItemText>
          {tab.label}
          {Boolean(tab.count) && <span className="tab-link-counter">{tab.count}</span>}
        </FilterTabsItemText>
        {tab.showTooltip && (
          <Tooltip id={tabTooltipId}>
            <span>{tab.tooltip}</span>
          </Tooltip>
        )}
      </FilterTabsItem>
    );
  });

  return (
    <FilterTabsStyles>
      <FilterTabsList>
        {renderTabs()}
      </FilterTabsList>
    </FilterTabsStyles>
  );
};

export default observer(FilterTabs);
