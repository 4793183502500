import React from "react";
import { Page, Content } from "./Layout.styles";
import TopNavigate from "../TopNavigate/TopNavigate";

type LayoutTypes = {
  className: string,
  locale: string,
  children: any,
  domain?: string
}

const Layout = ({
  className,
  locale,
  children,
  domain,
}: LayoutTypes) => (
  <Page className={className}>
    <TopNavigate domain={domain} locale={locale} />
    <Content>{children}</Content>
  </Page>
);

export default Layout;
