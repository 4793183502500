import React from "react";
import PropTypes from "prop-types";
import { GroupStyles } from "./Group.styles";

const Group = ({ children }) => (
  <GroupStyles>
    {children}
  </GroupStyles>
);

Group.propTypes = {
  children: PropTypes.array,
};

export default Group;
