import styled from "styled-components";

const PageCountSelectStyles = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  .page-counter-select {
    width: 72px;
    margin-left: 8px;
  }
`;

export {
  PageCountSelectStyles,
};
