import React from "react";
import { observer } from "mobx-react-lite";
import Field from "ati-ui-react/unstyled/components/Form/Field"; // TODO перейти на новые компоненты
import InputField from "ati-ui-react/unstyled/components/InputField"; // TODO перейти на новые компоненты
import { useStore } from "../../../../store/RootStore";
import {
  FormCarParamsStyles,
  FormCarParamsField,
  FormCarParamsRow,
  FormCarParamsLabel,
} from "./FormCarParams.styles";
import i18nStrings from "../strings";

const FormCarParams = () => {
  const store = useStore();
  const { carParams } = store.formsModule;
  const { onChangeFieldCarParams, values, onBlurField, onEnter } = carParams;
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale || "ru"];

  const handleChange = (group, field, event) => {
    onChangeFieldCarParams(group, field, event.target.value);
  };

  return (
    <FormCarParamsStyles>
      <FormCarParamsField>
        <FormCarParamsLabel>{strings.carryingCapacity}</FormCarParamsLabel>
        <FormCarParamsRow>
          <Field size="xsmall">
            <InputField
              className="form-input"
              placeholder={strings.from}
              value={values.carrying.from}
              onBlur={() => onBlurField("carrying")}
              onChange={(event) => handleChange("carrying", "from", event)}
              onKeyDown={onEnter}
            />
          </Field>
          <Field size="xsmall">
            <InputField
              className="form-input"
              placeholder={strings.upTo}
              value={values.carrying.to}
              onBlur={() => onBlurField("carrying")}
              onChange={(event) => handleChange("carrying", "to", event)}
              onKeyDown={onEnter}
            />
          </Field>
        </FormCarParamsRow>
      </FormCarParamsField>
      <FormCarParamsField>
        <FormCarParamsLabel>{strings.bodyVolume}</FormCarParamsLabel>
        <FormCarParamsRow>
          <Field size="xsmall">
            <InputField
              className="form-input"
              placeholder={strings.from}
              value={values.volume.from}
              onBlur={() => onBlurField("volume")}
              onChange={(event) => handleChange("volume", "from", event)}
              onKeyDown={onEnter}
            />
          </Field>
          <Field size="xsmall">
            <InputField
              className="form-input"
              placeholder={strings.upTo}
              value={values.volume.to}
              onBlur={() => onBlurField("volume")}
              onChange={(event) => handleChange("volume", "to", event)}
              onKeyDown={onEnter}
            />
          </Field>
        </FormCarParamsRow>
      </FormCarParamsField>
    </FormCarParamsStyles>
  );
};

export default observer(FormCarParams);
