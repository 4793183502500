import styled from "styled-components";

const BreadcrumbsWrapper = styled.div`
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.color_gray};
  display: none;
  &:hover {
    color: ${(props) => props.theme.colors.color_blue_pressed};
    text-decoration: underline;
  }

  .breadcrumbs-link {
    font-size: 12px;
    color: ${(props) => props.theme.colors.color_gray};
    svg path {
      fill: ${(props) => props.theme.colors.color_gray};
    }
    svg {
      position: relative;
      top: 1px;
    }
  }

  @media (max-width: 1180px) {
    display: inline-block;
  }
`;

export {
  BreadcrumbsWrapper,
};
