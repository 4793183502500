const ru = {
  title: "Новости",
  searchOnly: "Поиск по площадкам доступен только",
  participants: "участникам ATI.SU",
  repeat: "Повторить",
};

const en = {
  title: "News",
  searchOnly: "Search by platform is available only to",
  participants: "ATI.SU participants",
  repeat: "Repeat",
};

export default { ru, en };
