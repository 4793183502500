import React from "react";
import Icon from "@components/icon";
import { HelpLinkStyles, HelpLinksItem, HelpLink, HelpLinkDesktop, HelpLinkLessDesktop } from "./HelpLinks.styles";
import { useStore } from "../../../../store/RootStore";
import i18nStrings from "../strings";

const HelpLinks = ({ domain }) => {
  const store = useStore();
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale || "ru"];

  return (
    <HelpLinkStyles>
      <HelpLinksItem>
        <HelpLink href={`//help.${domain}/kak-najti-tender`} target="_blank">
          <Icon
            className="icon doc-icon"
            color="var(--glz-color-neutral-tone-3)"
            fillRule="evenodd"
            height={14}
            name="ic_doc"
            viewBox="0 0 20 24"
            width={14}
          />
          <HelpLinkDesktop className="link-text">{strings.desktopTexts.helpLink}</HelpLinkDesktop>
          <HelpLinkLessDesktop className="link-text">{strings.tabletTexts.helpLink}</HelpLinkLessDesktop>
        </HelpLink>
      </HelpLinksItem>
    </HelpLinkStyles>
  );
};

export default HelpLinks;
