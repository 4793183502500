import React from "react";
import dynamic from "next/dynamic";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store/RootStore";
import Filter from "../../Content/Filter";
import FilterSubscribePage from "../../Content/Filter/FilterSubscribe/FilterSubscribePage";
import ResultList from "../../Content/ResultList";
import GridUserData from "../../Common/GridUserData";
import FilterSubscribePopup from "../../Content/Filter/FilterSubscribe/FilterSubscribePopup/FilterSubscribePopup";
import { tendersTabs } from "../../../const";
import { ContentTenderSeachStyles } from "./ContentTenderSeach.styles";

const ExtendedSearchPopup = dynamic(() => import("../../Common/ExtendedSearchPopup"), { ssr: false });
const FirmScrollsPopup = dynamic(() => import("../../Common/FirmScrollsPopup"), { ssr: false });

const ContentTenderSeach = ({ isAtiApp, locale, hasAtiWithoutAdsLicence, domain }) => {
  const store = useStore();
  const { activeTab } = store.tabsModule;
  const { showExtendedSearchPopup } = store.formsModule.firms;
  const {
    showFirmListPopup,
    onCloseListPopup,
    firmListPopupType,
    currentList,
  } = store.listPopupModule;

  const searchTabVisible = activeTab === tendersTabs.TENDER_SEARCH_TAB;
  const subsribeTabVisible = activeTab === tendersTabs.TENDER_SUBSCRIPTIONS_TAB;
    return (
      <ContentTenderSeachStyles>
        {searchTabVisible && <Filter domain={domain} />}
        {showFirmListPopup
            && (
              <React.Suspense fallback={<div />}>
                <FirmScrollsPopup
                  payload={{
                    id: currentList?.id,
                  }}
                  stage={firmListPopupType}
                  onClose={onCloseListPopup}
                />
              </React.Suspense>
            )}
        {showExtendedSearchPopup
          && (
            <React.Suspense fallback={<div />}>
              <ExtendedSearchPopup />
            </React.Suspense>
          )}
        {subsribeTabVisible && <FilterSubscribePage />}
        <ResultList className={subsribeTabVisible ? "tenders-result-list-hidden" : ""} id="tenders-result-list" />
        {locale !== "en" && <GridUserData isAtiApp={isAtiApp} locale={locale} showBanners={!hasAtiWithoutAdsLicence} />}
        <FilterSubscribePopup />
      </ContentTenderSeachStyles>
    );
};

export default observer(ContentTenderSeach);
