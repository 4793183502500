import { observer } from "mobx-react-lite";
import { TendersSearchProps } from "../types/index";
import TenderSeach from "../components/TenderSeach/TenderSeach";

const HomePageWrapper = (props: TendersSearchProps) => {
  const {
    locale,
  } = props;
  return (
    <TenderSeach
      {...props}
      locale={locale}
    />
  );
};

export const getServerSideProps = (props: { query: TendersSearchProps }) => ({
    props: props.query,
  });

const connected = observer(HomePageWrapper);

export { connected as default };
