import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import FilterBottom from "./FilterBottom";
import FormDirection from "../Forms/FormDirection";
import FormCarParams from "../Forms/FormCarParams";
import FormCarTypes from "../Forms/FormCarTypes";
import FormCargoParams from "../Forms/FormCargoParams";
import FormPayment from "../Forms/FormPayment";
import FormBoards from "../Forms/FormBoards";
import FormFirms from "../Forms/FormFirms";
import FormExtra from "../Forms/FormExtra";
import ToggleLabel from "../../Common/ToggleLabel";
import FilterSubscribeInfo from "./FilterSubscribe/FilterSubscribeInfo";
import { useStore } from "../../../store/RootStore";
import {
  FilterStyles,
  FilterRow,
  FilterCell,
  FilterGrid,
  FilterExtraLink,
  FilterToggler,
  FilterLines,
} from "./Filter.styles";
import i18nStrings from "./strings";

const Filter = ({ domain }) => {
  const store = useStore();
  const { showAdditionParams, setShowAdditionParams, touchedFormsCounter } = store.globalModule;
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale || "ru"];
  const counterText = touchedFormsCounter ? `(${touchedFormsCounter})` : "";
  const extraOpenLinkText = `${strings.allParameters} ${counterText}`;
  const extraCloseLinkText = showAdditionParams ? strings.collapse : extraOpenLinkText;

  return (
    <>
      <FilterStyles>
        <FilterRow className="top-line">
          <FilterCell>
            <FormDirection />
          </FilterCell>
          <FilterCell>
            <FormCarParams />
            {!showAdditionParams
            && (
            <FilterExtraLink>
              <ToggleLabel
                primary
                className="extra-link-open"
                open={showAdditionParams}
                onClick={setShowAdditionParams}
              >
                {extraOpenLinkText}
              </ToggleLabel>
            </FilterExtraLink>
            )}
          </FilterCell>
        </FilterRow>
        <FilterLines show={showAdditionParams}>
          <FilterRow className="middle-line">
            <FilterGrid>
              <FilterCell>
                <FormCarTypes />
              </FilterCell>
              <FilterCell>
                <FormCargoParams />
              </FilterCell>
            </FilterGrid>
            <FilterGrid>
              <FilterCell>
                <FormPayment />
              </FilterCell>
              <FilterCell>
                <FormBoards />
              </FilterCell>
            </FilterGrid>
          </FilterRow>
          <FilterRow className="bottom-line">
            <FilterGrid>
              <FilterCell>
                <FormFirms />
              </FilterCell>
              <FilterCell>
                <FormExtra />
              </FilterCell>
            </FilterGrid>
          </FilterRow>
        </FilterLines>
        <FilterToggler>
          <ToggleLabel
            primary
            className="extra-link-close"
            open={showAdditionParams}
            onClick={setShowAdditionParams}
          >
            {extraCloseLinkText}
          </ToggleLabel>
        </FilterToggler>
      </FilterStyles>
      <FilterSubscribeInfo locale={locale} />
      <FilterBottom domain={domain} />
    </>
  );
};

export default observer(Filter);
