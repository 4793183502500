import React from "react";
import { observer } from "mobx-react-lite";

import Popup from "ati-ui-react/unstyled/components/Popup"; // TODO перейти на новые компоненты
import Button from "ati-ui-react/unstyled/components/Button/v3"; // TODO перейти на новые компоненты
import Icon from "@components/icon";
import ArrowRightBoldIcon from "ati-ui-react/unstyled/components/icons/ArrowRightBold"; // TODO перейти на новые компоненты
import TelegramLogo from "../../../../img/telegram.svg";
import { useStore } from "../../../../store/RootStore";

import {
  PoupTitle,
  Description,
  Title,
  ChannelItem,
  ChannelIcon,
  ChannelContent,
  ChannelTitle,
  ChannelDescription,
  ChannelAction,
  ArrowButton,
} from "./SubscribePopup.styles";
import i18nStrings from "../strings";
import api from "../../../../api";

const SubscribePopup = () => {
  const store = useStore();
  const { hideSubscribePopup } = store.subscribePopupModule;
  const { isMobile } = store.mediaModule;
  const { locale } = store.localeModule;

  const strings = i18nStrings[locale || "ru"];
  const body = {
    actions: [
      {
        action: "SubscribeTender",
        product: "Tenders",
      },
    ],
  };

  const handleOpenLink = (e, link) => {
    e.stopPropagation();

    window.open(link);
    if (window.atiUser.is_user) {
      api.sendActivity(body);
    }
  };

  const handleItemClick = (e, link) => {
    if (!isMobile) return;
    handleOpenLink(e, link);
  };

  const renderChannels = () => {
    const items = [
      {
        title: strings.tendersATI,
        description: strings.messengerATI,
        link: "https://chat.ati.su/ln/nqbjphw3csm",
        icon: <Icon height="60" name="ic_messenger" width="60" />,
      },
      {
        title: strings.tendersATI,
        description: strings.telegram,
        link: "https://t.me/atitender",
        icon: <img height={60} src={TelegramLogo} width={60} />,
      },
    ];

    return (
      <div>
        {items.map((item) => (
          <ChannelItem key={item.link} onClick={(e) => handleItemClick(e, item.link)}>
            <ChannelIcon>{item.icon}</ChannelIcon>
            <ChannelContent>
              <ChannelTitle>{item.title}</ChannelTitle>
              <ChannelDescription>{item.description}</ChannelDescription>
            </ChannelContent>
            <ChannelAction>
              {isMobile ? (
                <ArrowButton onClick={(e) => handleOpenLink(e, item.link)}>
                  <ArrowRightBoldIcon color="var(--glz-color-neutral-tone-2)" />
                </ArrowButton>
              ) : (
                <Button theme="success" onClick={(e) => handleOpenLink(e, item.link)}>
                  {strings.subcribe}
                </Button>
              )}
            </ChannelAction>
          </ChannelItem>
        ))}
      </div>
    );
  };

  return (
    <Popup
      closeOnEscape
      closeOnOverlayClick
      title={<PoupTitle>{strings.subscribeToTheChannel}</PoupTitle>}
      onClose={hideSubscribePopup}
    >
      <Description>{strings.hostsSeveralTrucking}
      </Description>
      <Title>{strings.channelWithNewTenders}</Title>
      {renderChannels()}
    </Popup>
  );
};

export default observer(SubscribePopup);
