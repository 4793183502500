import React from "react";
import { observer } from "mobx-react-lite";
import Field from "ati-ui-react/unstyled/components/Form/Field"; // TODO перейти на новые компоненты
import Select from "@components/select";
import InputField from "ati-ui-react/unstyled/components/InputField"; // TODO перейти на новые компоненты
import ToggleLabel from "../../../Common/ToggleLabel";
import Row from "../../../Common/Row";
import Group from "../../../Common/Group";
import { useStore } from "../../../../store/RootStore";
import {
  FormCargoParamsStyles,
  FormCargoParamsAdrInfo,
  FormCargoFields,
} from "./FormCargoParams.styles";
import i18nStrings from "../strings";

const FormCargoParams = () => {
  const store = useStore();
  const { cargoParams } = store.formsModule;
  const { showForms, onChangeVisibleForm } = store.globalModule;
  const { locale } = store.localeModule;
  const { onChangeFieldCargoForm, values, adrOptions, onBlurField, onChangeSelect, onEnter } = cargoParams;
  const { adr, lengthField, widthField, heigthField } = values;
  const strings = i18nStrings[locale || "ru"];

  const isOpen = showForms.cargoParams;

  const handleChangeField = (group, field, event) => {
    onChangeFieldCargoForm(group, field, event.target.value);
  };

  const renderContent = () => {
    if (!isOpen) return null;
    return (
      <>
        <FormCargoFields>
          <FormCargoParamsAdrInfo>{strings.dangerousGoods}</FormCargoParamsAdrInfo>
          <Row label="ADR">
            <Select
              className="form-cargo-params-select"
              options={adrOptions}
              selectedValue={adr.select.value}
              onChange={onChangeSelect}
            />
          </Row>
        </FormCargoFields>
        <FormCargoFields>
          <Row label={strings.lengthValue}>
            <Group>
              <Field className="form-cargo-input" size="xsmall">
                <InputField
                  after={strings.after}
                  placeholder={strings.from}
                  value={lengthField.from}
                  onBlur={() => onBlurField("lengthField")}
                  onChange={(event) => handleChangeField("lengthField", "from", event)}
                  onKeyDown={onEnter}
                />
              </Field>
              <Field className="form-cargo-input" size="xsmall">
                <InputField
                  after={strings.after}
                  placeholder={strings.upTo}
                  value={lengthField.to}
                  onBlur={() => onBlurField("lengthField")}
                  onChange={(event) => handleChangeField("lengthField", "to", event)}
                  onKeyDown={onEnter}
                />
              </Field>
            </Group>
          </Row>
        </FormCargoFields>
        <FormCargoFields>
          <Row label={strings.widthValue}>
            <Group>
              <Field className="form-cargo-input" size="xsmall">
                <InputField
                  after={strings.after}
                  placeholder={strings.from}
                  value={widthField.from}
                  onBlur={() => onBlurField("widthField")}
                  onChange={(event) => handleChangeField("widthField", "from", event)}
                  onKeyDown={onEnter}
                />
              </Field>
              <Field className="form-cargo-input" size="xsmall">
                <InputField
                  after={strings.after}
                  placeholder={strings.upTo}
                  value={widthField.to}
                  onBlur={() => onBlurField("widthField")}
                  onChange={(event) => handleChangeField("widthField", "to", event)}
                  onKeyDown={onEnter}
                />
              </Field>
            </Group>
          </Row>
        </FormCargoFields>
        <FormCargoFields>
          <Row label={strings.heightValue}>
            <Group>
              <Field className="form-cargo-input" size="xsmall">
                <InputField
                  after={strings.after}
                  placeholder={strings.from}
                  value={heigthField.from}
                  onBlur={() => onBlurField("heigthField")}
                  onChange={(event) => handleChangeField("heigthField", "from", event)}
                  onKeyDown={onEnter}
                />
              </Field>
              <Field className="form-cargo-input" size="xsmall">
                <InputField
                  after={strings.after}
                  placeholder={strings.upTo}
                  value={heigthField.to}
                  onBlur={() => onBlurField("heigthField")}
                  onChange={(event) => handleChangeField("heigthField", "to", event)}
                  onKeyDown={onEnter}
                />
              </Field>
            </Group>
          </Row>
        </FormCargoFields>
      </>
    );
  };

  return (
    <FormCargoParamsStyles>
      <ToggleLabel open={isOpen} onClick={() => onChangeVisibleForm("cargoParams")}>{strings.dimensionsAndADR}</ToggleLabel>
      {renderContent()}
    </FormCargoParamsStyles>
  );
};

export default observer(FormCargoParams);
