const ru = {
  tendersByCountry: "Тендеры по странам",
  russia: "Россия",
  belarus: "Беларусь",
  international: "Международные",
};

const en = {
  tendersByCountry: "Tenders by country",
  russia: "Russia",
  belarus: "Belarus",
  international: "International",
};

export default { ru, en };
