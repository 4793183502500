const en = {
  countTextPlural: ["subscription", "subscriptions", "subscriptions"],
  countText: "{{count}} newsletter {{plural}}",
  empty: {
    title: "Subscriptions to new tenders will be here",
    description: "Save your time. Subscribe to the filter and get notifications about new tenders.",
  },
};

export default en;
