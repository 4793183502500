import styled from "styled-components";

export const SeoTendersWrapper = styled.div`
  margin: 70px 6px 0 6px;
  .tenders-header {
    margin-bottom: 16px;
  }
  .tenders-link {
    margin-right: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .links-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
`;
