import React from "react";
import { observer } from "mobx-react-lite";
import Select from "@components/select"; // TODO перейти на новые компоненты
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import OptionsSelector from "ati-ui-react/unstyled/components/OptionsSelector"; // TODO перейти на новые компоненты
import Icon from "@components/icon";
import FirmListOption from "./FirmListOption";
import { useStore } from "../../../../store/RootStore";
import SkeletonContainer from "../../../Common/SkeletonContainer";
import OptionSkeleton from "../../../Common/OptionSkeleton";
import LoadError from "../../../Common/LoadError";
import {
  FormFirmListStyles,
  FormFirmListTitle,
  FormFirmListField,
  FormFirmListAddLink,
} from "./FormFirmList.styles";
import i18nStrings from "../strings";

const FormFirmList = () => {
  const store = useStore();
  const { isDesktop } = store.mediaModule;
  const { onOpenCreateListPopup, onOpenEditListPopup } = store.listPopupModule;
  const {
    firmListTypeOptions,
    values,
    onChangeField,
    onSelectFirmsListItem,
    onClearSelectedFirmLists,
    onShowFirmsList,
    clearFirmList,
    firmListItemOptions,
    firmListLoaded,
    firmListServerError,
    getListsRequest,
  } = store.formsModule.firms;
  const { firm } = values;
  const { locale } = store.localeModule;

  const strings = i18nStrings[locale || "ru"];

  const renderOption = (option) => (
    <FirmListOption
      locale={locale}
      option={option}
      onOpen={() => onOpenEditListPopup(option)}
    />
  );

  const handleClose = () => {
    onShowFirmsList(false);
    clearFirmList();
  };

  const renderSelector = () => {
    if (!firmListLoaded) {
      return (
        <SkeletonContainer>
          <OptionSkeleton />
        </SkeletonContainer>
      );
    }

    if (firmListServerError) {
      return (
        <SkeletonContainer>
          <LoadError onClick={getListsRequest}>
            {strings.failedToLoadCompanyLists}
          </LoadError>
        </SkeletonContainer>
      );
    }

    return (
      <OptionsSelector
        className="firm-list-selector"
        clearAllSelectedValues={onClearSelectedFirmLists}
        options={firmListItemOptions}
        popupClassName="firm-list-selector-popup"
        renderCheckboxContent={renderOption}
        selectedValues={firm.optionSelector}
        onChange={onSelectFirmsListItem}
      />
    );
  };

  return (
    <FormFirmListStyles>
      <FormFirmListTitle>
        <span>{strings.searchByCompanyList}</span>
        {!isDesktop
          && (
          <Link
            className="form-list-title-link"
            type="pseudo"
            onClick={handleClose}
          >
            {strings.hide}
          </Link>
      )}
      </FormFirmListTitle>
      <FormFirmListField className="form-list-select">
        <Select
          options={firmListTypeOptions}
          selectedValue={firm.select.value}
          onChange={(option) => onChangeField("firm", "select", option)}
        />
      </FormFirmListField>
      <FormFirmListField>
        {renderSelector()}
      </FormFirmListField>
      <FormFirmListAddLink>
        <Link
          small
          before={<Icon color="var(--glz-color-primary)" name="ic_plus-solid" size="s" />}
          className="add-link"
          type="pseudo"
          onClick={onOpenCreateListPopup}
        >
          {strings.createANewList}
        </Link>
      </FormFirmListAddLink>
    </FormFirmListStyles>
  );
};

export default observer(FormFirmList);
