import React from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import getEnvironDomain from "ati-utils/getEnvironDomain";
import Select from "@components/select";
import Autosuggest from "ati-ui-react/unstyled/components/Autosuggest"; // TODO перейти на новые компоненты
import Link from "@components/link"; // TODO перейти на новые компоненты
import Icon from "@components/icon";
import FormFirmList from "../FormFirmList";
import ToggleLabel from "../../../Common/ToggleLabel";
import QuickFirmsSearch from "./QuickFirmsSearch";
import { useStore } from "../../../../store/RootStore";
import {
  FormFirmsStyles,
  FormFirmsField,
  FormFirmsListLink,
  FormFirmsListErrorMessage,
} from "./FormFirms.styles";
import i18nStrings from "../strings";

const FormFirms = () => {
  const store = useStore();
  const { showForms, onChangeVisibleForm, isAuthorized } = store.globalModule;
  const {
    pointOptions,
    values,
    onChangeField,
    showFirmsList,
    onShowFirmsList,
    participantError,
    getCitiesSuggestions,
    citySuggestion,
    onChangeCitiesField,
    onSuggestionCitySelected,
  } = store.formsModule.firms;

  const { locale } = store.localeModule;
  const { point, city } = values;

  const isOpen = showForms.firms;
  const strings = i18nStrings[locale || "ru"];

  const handleShowFirmList = () => {
    onShowFirmsList(true);
  };

  const renderFirmList = () => {
    if (!showFirmsList) {
      return (
        <FormFirmsListLink>
          <Link
            before={<Icon name="ic_list" size="m" />}
            className="firms-list-link"
            mode="pseudo"
            size="small"
            onClick={handleShowFirmList}
          >
            {strings.searchByCompanyList}
          </Link>
        </FormFirmsListLink>
      );
    }
    return (
      <FormFirmList />
    );
  };

  const getLicenseUrl = () => {
    if (!isAuthorized) return `//billing.${getEnvironDomain()}/prices`;
    return `//billing.${getEnvironDomain()}/addinvoice?option=1&services=76`;
  };

  const renderErrorMessage = () => (
    <FormFirmsListErrorMessage>
      <div>{strings.onlyAvailableToPaid}</div>
      <Link
        href={getLicenseUrl()}
        size="medium"
        target="_blank"
      >
        {strings.buyLicense}
      </Link>
    </FormFirmsListErrorMessage>
  );

  const renderForms = () => (
    <div>
      <FormFirmsField>
        <Select
          className="form-firms-select"
          options={pointOptions}
          selectedValue={point.select.value}
          onChange={(option) => onChangeField("point", "select", option)}
        />
      </FormFirmsField>
      <FormFirmsField>
        <Autosuggest
          locale={locale}
          placeholder={strings.cityRegistationCompany}
          searchProperty="text"
          suggestions={toJS(citySuggestion)}
          value={city.autosuggest}
          valueProperty="text"
          onChange={(event) => onChangeCitiesField(event)}
          onSuggestionSelected={onSuggestionCitySelected}
          onSuggestionsFetchRequested={getCitiesSuggestions}
        />
      </FormFirmsField>
      <FormFirmsField>
        <QuickFirmsSearch />
      </FormFirmsField>
      {renderFirmList()}
    </div>
  );

  const renderContent = () => {
    if (!isOpen) return null;
    if (participantError || !isAuthorized) return renderErrorMessage();
    return renderForms();
  };

  return (
    <FormFirmsStyles>
      <ToggleLabel open={isOpen} onClick={() => onChangeVisibleForm("firms")}>{strings.searchByCompany}</ToggleLabel>
      {renderContent()}
    </FormFirmsStyles>
  );
};

export default observer(FormFirms);
