import en from "./strings.en";

const ru = {
  title: "Подписаться на фильтр",
  from: "Откуда:",
  to: "Куда:",
  email: "Email для уведомлений",
  emailDescription: "Экономьте своё время. Получайте уведомления о\u00A0новых тендерах.",
  action: "подписаться",
  cancel: "Отменить",
};

export default { ru, en: { ...ru, ...en } };
