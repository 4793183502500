import React from "react";
import Heading from "ati-ui-react/unstyled/components/Heading"; // TODO перейти на новые компоненты
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import propTypes from "prop-types";
import { SeoTendersWrapper } from "./SeoTenders.styles";
import strings from "./strings";

const SeoTenders = ({ locale }) => (
  <SeoTendersWrapper>
    <Heading h2 className="tenders-header">{strings[locale].tendersByCountry}</Heading>
    <div className="links-wrapper">
      <Link className="tenders-link" href="/tenders/russia" target="_blank">{strings[locale].russia}</Link>
      <Link className="tenders-link" href="/tenders/belarus" target="_blank">{strings[locale].belarus}</Link>
      <Link className="tenders-link" href="/tenders/international" target="_blank">{strings[locale].international}</Link>
    </div>
  </SeoTendersWrapper>
);

SeoTenders.propTypes = {
  locale: propTypes.string,
};

export default SeoTenders;
