import styled from "styled-components";

export const FilterSubscribePopoverWrapper = styled.div`
  display: inline-block;
  .popover-wrapper {
    border-radius: 6px;
    height: 100%;
  }
  .popover-footer {
    display: flex;
    align-items: center;
  }
`;
