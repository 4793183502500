import React from "react";
import dynamic from "next/dynamic";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import Autosuggest from "ati-ui-react/unstyled/components/Autosuggest"; // TODO перейти на новые компоненты
import arrowsIcon from "../../../../img/arrows.svg";
import { useStore } from "../../../../store/RootStore";
import {
  FormDirectionStyles,
  FormDirectionField,
  FormDirectionLabel,
  FormDirectionIcon,
} from "./FormDirection.styles";
import i18nStrings from "../strings";

const Tooltip = dynamic(() => import("../../../Common/Tooltip"), { ssr: false });

const FormDirection = () => {
  const store = useStore();
  const { direction } = store.formsModule;
  const { locale } = store.localeModule;
  const {
    onChangeFieldDirection,
    reverseFieldsDirection,
    values,
    handleSuggest,
    suggestion,
    onBlurField,
    setSelectedSuggests,
  } = direction;

  const strings = i18nStrings[locale || "ru"];

  const handleRevert = () => {
    reverseFieldsDirection();
  };

  const suggestionArray = toJS(suggestion);

  return (
    <>
      <Tooltip id="direction-tooltip">
        <span>{strings.theOppositeDirection}</span>
      </Tooltip>
      <FormDirectionStyles>
        <FormDirectionField>
          <FormDirectionLabel>{strings.whence}</FormDirectionLabel>
          <Autosuggest
            locale={locale}
            placeholder={strings.forExampleMoscow}
            searchProperty="text"
            suggestions={suggestionArray}
            value={values.direction.from}
            valueProperty="text"
            onBlur={onBlurField}
            onChange={(event) => onChangeFieldDirection("direction", "from", event)}
            onSuggestionSelected={(event, value) => setSelectedSuggests("from", value.suggestion)}
            onSuggestionsFetchRequested={() => handleSuggest("from")}
          />
        </FormDirectionField>
        <FormDirectionIcon onClick={handleRevert}>
          <span data-tip data-for="direction-tooltip">
            <img alt="" className="direction-icon" src={arrowsIcon} />
          </span>
        </FormDirectionIcon>
        <FormDirectionField>
          <FormDirectionLabel>{strings.where}</FormDirectionLabel>
          <Autosuggest
            locale={locale}
            placeholder={strings.forExampleStPetersburg}
            searchProperty="text"
            suggestions={suggestionArray}
            value={values.direction.to}
            valueProperty="text"
            onBlur={onBlurField}
            onChange={(event) => onChangeFieldDirection("direction", "to", event)}
            onSuggestionSelected={(event, value) => setSelectedSuggests("to", value.suggestion)}
            onSuggestionsFetchRequested={() => handleSuggest("to")}
          />
        </FormDirectionField>
      </FormDirectionStyles>
    </>
  );
};

export default observer(FormDirection);
