import styled from "styled-components";

const RowStyles = styled.div`
  display: flex;
`;

const RowLabel = styled.div`
  margin-top: 9px;
  font-size: 12px;
  min-width: 78px;
  max-width: 78px;
  padding-right: 12px;
`;

export {
  RowStyles,
  RowLabel,
};
