import React from "react";
import Skeleton from "@components/skeleton";
import { OptionSkeletonStyles } from "./OptionSkeleton.styles";

const CarTypesSkeleton = () => (
  <OptionSkeletonStyles>
    <Skeleton className="skeleton-line skeleton-line--base" />
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line skeleton-line--base" />
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line" />
  </OptionSkeletonStyles>
);

export default CarTypesSkeleton;
