import styled from "styled-components";

const QuickFirmsSearchStyles = styled.div`
  .firm-search-wrapper > div > div {
    width: 100%;
    min-width: 100%;
  }
  .firm-search-wrapper-warning label {
    border-bottom: 2px solid ${(props) => props.theme.colors.warning_message};
  }
  .firm-search-wrapper-disabled {
    input, label {
      cursor: not-allowed;
    }
    label {
      background: var(--glz-color-neutral);
    }
    label:hover {
      border-color: var(--glz-color-neutral-tone-3);
    }
  }
`;

const QuickFirmsSearchWarning = styled.div`
  display: block;
  font-size: 11px;
  color: ${(props) => props.theme.colors.warning_message};
  margin-top: 8px;
`;

export {
  QuickFirmsSearchStyles,
  QuickFirmsSearchWarning,
};
