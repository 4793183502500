import styled from "styled-components";

export const FormBoardsErrorStyles = styled.div`
  font-size: 12px;
`;

export const FormBoardsErrorContainer = styled.div`
  width: 260px;
  height: 50px;
  background-color: var(--glz-color-neutral-tone-0);
  padding: 10px 6px;
  border: 1px solid var(--glz-color-neutral-tone-3);
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

export const FormBoardsErrorText = styled.span`
  display: block;
  color: var(--glz-color-error);
`;

export const FormBoardsErrorTextWrapper = styled.div`
  margin-top: 12px;
  ${FormBoardsErrorText} {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
