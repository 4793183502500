import styled from "styled-components";

export const PoupTitle = styled.div`
  max-width: 400px;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.1px;
  @media (max-width: 651px) {
    font-size: 18px;
  }
`;

export const ChannelItem = styled.div`
  border: 1px solid ${(props) => props.theme.colors.color_gray_light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 10px;
  margin-top: 16px;
  max-width: 450px;
`;

export const ChannelIcon = styled.div`
  svg, img {
    @media (max-width: 651px) {
      width: 48px;
      height: 48px;
    }
  }
`;

export const ChannelContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-left: 16px;
  margin-right: auto;
`;

export const ChannelTitle = styled.div`
  font-weight: bold;
`;

export const ChannelDescription = styled.div``;

export const ChannelAction = styled.div``;

export const ArrowButton = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
