import styled from "styled-components";

const SkeletonContainerStyles = styled.div`
  width: 260px;
  height: 196px;
  background-color: var(--glz-color-neutral-tone-0);
  padding: 13px 6px;
  border: 1px solid var(--glz-color-neutral-tone-3);
  border-radius: 2px;
`;

export {
  SkeletonContainerStyles,
};
