import React from "react";
import PropTypes from "prop-types";
import Select from "@components/select";
import { availableCountPerPage, defaultPerPage } from "../../../../const";
import { PageCountSelectStyles } from "./PageCountSelect.styles";
import i18nStrings from "../strings";

const PageCountSelect = ({ count, onSelect, disabled, locale }) => {
  const options = availableCountPerPage.map((value) => ({ value, label: value }));

  const currentOptions = options.find((option) => option.value === count);
  const strings = i18nStrings[locale || "ru"];

  return (
    <PageCountSelectStyles>
      <span>{strings.showTenders}</span>
      <Select
        className="page-counter-select"
        disabled={disabled}
        options={options}
        value={currentOptions}
        onChange={onSelect}
      />
    </PageCountSelectStyles>
  );
};

PageCountSelect.defaultProps = {
  count: defaultPerPage,
  onSelect: () => {},
  disabled: false,
};

PageCountSelect.propTypes = {
  count: PropTypes.number,
  disabled: PropTypes.bool,
  locale: PropTypes.string,
  onSelect: PropTypes.func,
};

export default PageCountSelect;
