import styled from "styled-components";

export const FormBoardsInfoIcon = styled.div`
  display: flex;
  margin-left: 6px;
  position: relative;
`;

export const FormBoardsBadgeStyles = styled.div`
  display: flex;
  max-width: 180px;
  padding: 6px 8px;
  border-radius: 2px;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
`;
