import styled from "styled-components";

export const FilterRow = styled.div`
  padding: 20px 24px;
  display: flex;

  @media (max-width: 1180px) {
    display: block;
    padding: 0;
    background-color: transparent;
  }
`;

export const FilterLines = styled.div`
  > :nth-of-type(odd) {
    background-color: ${(props) => props.theme.colors.color_button_disabled};
  }
  @media (max-width: 1180px) {
    display: ${({ show }) => (show ? "block" : "none")};
  }
`;

export const FilterGrid = styled.div`
  display: flex;
  @media (max-width: 1180px) {
    border-top: 1px solid ${(props) => props.theme.colors.filter_line};
    padding: 16px 8px;
  }
  @media (max-width: 651px) {
    display: block;
    border-top: 0;
    padding: 0;
  }
`;

export const FilterCell = styled.div`
  position: relative;
  @media (max-width: 651px) {
    border-top: 1px solid ${(props) => props.theme.colors.filter_line};
    padding: 16px 8px;
  }
`;

export const FilterStyles = styled.div`
  background-color: ${(props) => props.theme.colors.filter_color};
  box-shadow: rgba(var(--glz-color-neutral-tone-5-rgb), 0.14) 0px 2px 1px 0px;
  border-radius: 0px 0px 8px 8px;
  min-height: 80px;
  @media (max-width: 1180px) {
    background-color: ${(props) => props.theme.colors.color_button_disabled};
    border-radius: 0px 8px 8px 8px;
  }

  /** Сетка */
  .top-line {
    justify-content: space-between;

    @media (max-width: 1180px) {
      padding: 16px 8px;
      background-color: ${(props) => props.theme.colors.filter_color};
      border-radius: 0;
    }

    ${FilterCell}{
      @media (max-width: 651px) {
        border-top: transparent;
        padding: 0;
      }
    }

    ${FilterCell}:first-child{
      flex-basis: 100%;
      margin-right: 112px;
      @media (max-width: 1180px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    ${FilterCell}:last-child{
      @media (max-width: 1180px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @media (max-width: 651px) {
        width: 100%;
      }
    }
  }

  .middle-line {
    ${FilterGrid} ${FilterCell} {
      margin-right: 38px;
    }

    @media (max-width: 1180px) {
      width: 100%;
      justify-content: space-between;

      ${FilterGrid} ${FilterCell} {
        margin-right: 0px;
      }

      ${FilterCell}{
        width: 100%;
        margin-right: 0;
        &:last-child {
          padding-left: 27px;
        }
      }
    }

    @media (max-width: 651px) {
      ${FilterCell}:last-child {
        padding-left: 8px;
      }
    }
  }

  .bottom-line {
    ${FilterGrid} ${FilterCell} {
      margin-right: 39px;
    }

    ${FilterGrid}:last-child {
      ${FilterCell} {
        margin-right: 40px;
      }
    };

    @media (max-width: 1180px) {
      width: 100%;
      justify-content: space-between;
      ${FilterCell} {
        width: 100%;
        &:nth-child(2) {
          padding-left: 27px;
        }
      }
    }

    @media (max-width: 651px) {
      ${FilterCell}:nth-child(2) {
        padding-left: 8px;
      }
    }
  }
`;

export const FilterExtraLink = styled.div`
  display: none;
  margin-right: 16px;
  margin-top: 14px;
  .extra-link-open {
    margin-bottom: 0;
  }

  @media (min-width: 652px) and (max-width: 1179px) {
    display: block;
  }
`;

export const FilterToggler = styled.div`
  display: none;
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.filter_line};
  text-align: center;
  background-color: ${(props) => props.theme.colors.filter_color};
  .extra-link-close {
    margin-bottom: 0;
  }

  @media (max-width: 651px) {
    display: block;
  }
`;
