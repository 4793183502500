import styled from "styled-components";

export const SearchControlStyles = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const SearchControlButton = styled.div`
  display: flex;
  @media (max-width: 700px) {
    order: -1;
    margin-bottom: 16px;
    button {
      min-width: 247px;
    }
  }
`;

export const SearchControlLink = styled.div`
  margin-right: 24px;
  .link-element {
    font-size: 12px;
  }

  &.subscribe-link {
    @media (max-width: 700px) {
      display: none;
    }
  }
`;
