import en from "./strings.en";

const ru = {
  countTextPlural: ["подписка", "подписки", "подписок"],
  countText: "{{count}} {{plural}} на рассылку",
  empty: {
    title: "Здесь будут подписки на новые тендеры",
    description: "Экономьте своё время. Попишитесь на фильтр и получайте уведомления о новых тендерах.",
  },
};

export default { ru, en: { ...ru, ...en } };
