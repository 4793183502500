import styled from "styled-components";

export const FilterBottomStyles = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    display: block;
  }
`;

export const FilterBottomCell = styled.div`
  &:first-child {
    display: flex;
    justify-content: space-between;
  }
`;

export const FilterBottomSubscribeWrapper = styled.div`
  @media (min-width: 701px) {
    display: none;
  }
`;
