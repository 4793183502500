import React from "react";
import PropTypes from "prop-types";
import { SkeletonContainerStyles } from "./SkeletonContainer.styles";

const SkeletonContainer = ({ children }) => (
  <SkeletonContainerStyles>
    {children}
  </SkeletonContainerStyles>
);

SkeletonContainer.propTypes = {
  children: PropTypes.object,
};

export default SkeletonContainer;
