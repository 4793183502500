import React from "react";
import { observer } from "mobx-react-lite";
import Popup from "ati-ui-react/unstyled/components/Popup"; // TODO перейти на новые компоненты
import InputField from "ati-ui-react/unstyled/components/InputField"; // TODO перейти на новые компоненты
import { useStore } from "../../../../../store/RootStore";
import i18nStrings from "./strings";
import {
  SubscribePopupWrapper,
  SubscribePopupContent,
  SubscribePopupDirection,
  SubscribePopupDirectionItem,
  SubscribePopupDirectionItemLabel,
  SubscribePopupRow,
  SubscribePopupInputLabel,
  SubscribePopupEmail,
  SubscribePopupInputDescription,
} from "./FilterSubscribePopup.styles";

const FilterSubscribePopup = () => {
  const store = useStore();
  const { locale } = store.localeModule;
  const { direction } = store.formsModule.direction.values;
  const {
    showFilterSubscribePopup,
    setShowFilterSubscribePopup,
    onSendSubscribe,
    email,
    setEmail,
    emailErrorMessage,
    popupLoading,
  } = store.filterSubscribeModule;
  const showDirectionInfo = direction.from || direction.to;
  const strings = i18nStrings[locale || "ru"];

  const renderDirectionItem = (type, currentDirection) => {
    if (!currentDirection) return null;
    return (
      <SubscribePopupDirectionItem>
        <SubscribePopupDirectionItemLabel>
          {strings[type]}
        </SubscribePopupDirectionItemLabel>
        <span>
          {currentDirection}
        </span>
      </SubscribePopupDirectionItem>
    );
  };

  const renderEmailInput = () => (
    <SubscribePopupRow>
      <SubscribePopupInputLabel>{strings.email}</SubscribePopupInputLabel>
      <SubscribePopupEmail>
        <InputField
          className="input-field"
          disabled={popupLoading}
          errorClassName={emailErrorMessage && "input-field-error"}
          errorDescr={emailErrorMessage}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <SubscribePopupInputDescription>{strings.emailDescription}</SubscribePopupInputDescription>
      </SubscribePopupEmail>
    </SubscribePopupRow>
  );

  if (!showFilterSubscribePopup) return null;

  return (
    <SubscribePopupWrapper>
      <Popup
        closeOnEscape
        closeOnOverlayClick
        whiteActionArea
        alternateAction={{
          name: strings.cancel,
          action: () => setShowFilterSubscribePopup(false),
        }}
        footerClassName="test"
        isPrimaryActionLoading={popupLoading}
        primaryAction={{
          name: strings.action,
          action: () => onSendSubscribe(),
        }}
        title={strings.title}
        onClose={() => setShowFilterSubscribePopup(false)}
      >
        <SubscribePopupContent>
          {showDirectionInfo
          && (
          <SubscribePopupDirection>
            {renderDirectionItem("from", direction.from)}
            {renderDirectionItem("to", direction.to)}
          </SubscribePopupDirection>
)}
          {renderEmailInput()}
        </SubscribePopupContent>
      </Popup>
    </SubscribePopupWrapper>
  );
};

export default observer(FilterSubscribePopup);
