import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Link from "@components/link";
import i18nStrings from "./strings";
import FilterSubscribeDeletePopover from "../FilterSubscribeDeletePopover";
import { useStore } from "../../../../../store/RootStore";
import {
  FilterSubscribeLinkStyles,
  FilterSubscribeLinkEnable,
  FilterSubscribeLinkEnableText,
} from "./FilterSubscribeLink.styles";

const FilterSubscribeLink = () => {
  const store = useStore();
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale];
  const {
    setShowFilterSubscribePopup,
    cancelSubscription,
    showFilterInfo,
    showFilterCancelLink,
    showFilterSubscribeLink,
  } = store.filterSubscribeModule;

  const [visiblePopover, setVisiblePopover] = useState(false);

  if (showFilterCancelLink) {
    return (
      <FilterSubscribeLinkEnable>
        <FilterSubscribeLinkEnableText>{strings.subscribeEnable}</FilterSubscribeLinkEnableText>
        <FilterSubscribeDeletePopover
          locale={locale}
          visible={visiblePopover}
          onClose={() => setVisiblePopover(false)}
          onDelete={() => {
            cancelSubscription();
            setVisiblePopover(false);
          }}
          onVisibilityChange={() => {
            setVisiblePopover(false);
          }}
        >
          <Link className="link-element" onClick={() => setVisiblePopover(true)}>
            {strings.cancel}
          </Link>
        </FilterSubscribeDeletePopover>
      </FilterSubscribeLinkEnable>
    );
  }

  if (showFilterSubscribeLink) {
    return (
      <FilterSubscribeLinkStyles>
        <Link
          className={showFilterInfo ? "link-element filter-info-arrow" : "link-element"}
          onClick={() => setShowFilterSubscribePopup(true)}
        >
          {strings.subscribeToFilter}
        </Link>
      </FilterSubscribeLinkStyles>
);
  }

  return null;
};

export default observer(FilterSubscribeLink);
