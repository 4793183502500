const en = {
  failedToLoadBoard: "Failed to load platform list",
  selectAll: "Select all",
  clear: "Clear",
  boards: "Platforms",
  boardsNotAvailable: "Platforms are not available",
  dangerousGoods: "dangerous goods",
  lengthValue: "Length",
  after: "m",
  from: "from",
  upTo: "up to",
  widthValue: "Width",
  heightValue: "Height",
  dimensionsAndADR: "Dimensions and ADR",
  carryingCapacity: "Carrying capacity,\u00A0t",
  bodyVolume: "Body\u00A0volume,\u00A0m³",
  failedToLoadBodyTypes: "Failed to load body types",
  bodyTypes: "Body type",
  theOppositeDirection: "The opposite direction",
  whence: "From",
  forExampleMoscow: "For example, Moscow",
  where: "To",
  forExampleStPetersburg: "For example, St. Petersburg",
  status: "Status",
  view: "View",
  iDTheTender: "Tender\u00A0ID",
  additionalParams: "Additional parameters",
  company: ["company", "companies", "companies"],
  noParticipants: "no participants",
  failedToLoadCompanyLists: "Failed to load company lists",
  searchByCompanyList: "Search by company list",
  hide: "Hide",
  createANewList: "Create a new list",
  findACompany: "Find a company",
  onlyThisCompany: "The search will be only by this company.",
  onlyAvailableToPaid: "Only available to paid ATI.SU\u00A0participants.",
  buyLicense: "Buy a license",
  searchByCompany: "Search by company",
  first: "First",
  payment: "Payment",
  cashlessWithVAT: "cashless with VAT",
  cashlessWithouVAT: "cashless without VAT",
  deferredPayment: "Deferred payment, banking\u00A0days.",
  rate: "Rate",
  prepaid: "prepaid",
  cityRegistationCompany: "City of company registration",
  allParticipants: "All ATI.SU participants take part in this Platform",
};

export default en;
