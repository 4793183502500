import styled from "styled-components";

const FormCarParamsStyles = styled.div`
  display: flex;
  @media (max-width: 651px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const FormCarParamsField = styled.div`
  margin-right: 32px;
  width: 126px;
  @media (max-width: 1180px) {
    margin-right: 44px;
  }
  @media (max-width: 651px) {
    margin-right: 5px;
  }
  .form-input input {
    height: 38px;
    max-width: 61px;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const FormCarParamsLabel = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
`;

const FormCarParamsRow = styled.div`
  display: flex;
`;

export {
  FormCarParamsStyles,
  FormCarParamsLabel,
  FormCarParamsField,
  FormCarParamsRow,
};
