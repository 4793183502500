import React from "react";
import { observer } from "mobx-react-lite";

import Button from "ati-ui-react/unstyled/components/Button/v3"; // TODO перейти на новые компоненты
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import Icon from "@components/icon";
import ButtonGroup from "ati-ui-react/unstyled/components/ButtonGroup"; // TODO перейти на новые компоненты

import PageCountSelect from "../PageCountSelect";

import { useStore } from "../../../../store/RootStore";

import { ResultListControlStyles, ContentStyles, TextStyles, SourceButtonsStyles } from "./ResultListControl.styles";

import i18nStrings from "../strings";

const ResultListControl = () => {
  const store = useStore();
  const { isDesktop, isMobile } = store.mediaModule;
  const { pageTake, isInitialRequest, searchLoading, onChangePageTake, onSearch } = store.globalModule;
  const { showSubscribePopup } = store.subscribePopupModule;
  const { sourceOptions, setCurrentSource, currentSource } = store.sourceButtonsModule;
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale || "ru"];

  const renderText = () => {
    if (isInitialRequest) return <TextStyles>{strings.subscribeToNewTendersOnATISU}</TextStyles>;
    return <TextStyles>{strings.subscribeToNewTendersForThisDirection}</TextStyles>;
  };

  const renderSourceButtons = () => {
    const handleSelectCurrentSource = (event) => {
      if (event === currentSource) return;
      if (searchLoading) return;
      setCurrentSource(event);
      setTimeout(() => {
        onSearch({ initial: false });
      }, 200);
    };

    return (
      <SourceButtonsStyles>
        <ButtonGroup
          dataQa="ati-ui-button-group-qa"
          options={sourceOptions}
          selectedValue={currentSource}
          onSelectedValueChanged={handleSelectCurrentSource}
        />
      </SourceButtonsStyles>
    );
  };

  const renderSubscribe = () => {
    if (!isMobile && locale !== "en") {
      return (
        <ContentStyles>
          <Button size="small" theme="success" onClick={showSubscribePopup}>
            {strings.subcribe}
          </Button>
          {renderText()}
        </ContentStyles>
      );
    }

    return (
      <ContentStyles>
        {locale !== "en"
          && (
          <Link small before={<Icon c color="var(--glz-color-primary)" name="ic_bell" size="m" />} className="icon-bell" onClick={showSubscribePopup}>
            <div>{strings.subscribeToNewTenders}</div>
          </Link>
)}
      </ContentStyles>
    );
  };

  return (
    <ResultListControlStyles>
      {renderSourceButtons()}
      {renderSubscribe()}
      {isDesktop && <PageCountSelect count={pageTake} disabled={searchLoading} locale={locale} onSelect={onChangePageTake} />}
    </ResultListControlStyles>
  );
};

export default observer(ResultListControl);
