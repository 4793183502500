import styled from "styled-components";

const FormCargoParamsStyles = styled.div`
  width: 228px;
  z-index: 2000;
  .form-cargo-params-select {
    position: relative;
    z-index: 10;
  }
  @media (max-width: 651px) {
    width: 100%;
    .form-cargo-input {
      max-width: 100%;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const FormCargoFields = styled.div`
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FormCargoParamsAdrInfo = styled.span`
  display: inline-block;
  font-size: 12px;
  margin-left: 79px;
  margin-bottom: 4px;
`;

export {
  FormCargoParamsStyles,
  FormCargoParamsAdrInfo,
  FormCargoFields,
};
