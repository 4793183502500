import styled from "styled-components";

const ListStyles = styled.div`
  padding-top: 55px;
  font-size: 12px;
  &.tenders-result-list-hidden {
    display: none;
  }
`;

const ListEmptyContent = styled.div`
  text-align: center;
  padding: 160px 8px;
  font-size: 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1180px) {
    padding: 80px 8px;
  }
  @media (max-width: 651px) {
    font-size: 14px;
  }
  .empty-icon {
    display: inline-block;
    margin-bottom: 16px;
    position: relative;
    svg path {
      fill: ${(props) => props.theme.colors.color_darkgray};
    }
  }
`;

const ListEmptyTitle = styled.div`
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  @media (max-width: 1180px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
  }
`;

const ListEmptyDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  margin-bottom: 16px;
  max-width: 416px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1180px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
  }
`;

const ListHeading = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 8px 16px;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.result_heading_border};
  color: ${(props) => props.theme.colors.color_darkgray};
`;

const ListHeadingItem = styled.li`
  flex-grow: 1;
  padding-right: 8px;
  &:nth-child(1) {
    flex-basis: 19.45%;
  }
  &:nth-child(2) {
    flex-basis: 20.77%;
  }
  &:nth-child(3) {
    flex-basis: 31.95%;
  }
  &:nth-child(4) {
    flex-basis: 21.83%;
    padding-right: 0;
  }
`;

const ListTop = styled.div`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 525px) {
    flex-direction: column;
  }
`;

const ListTopWrapper = styled.div`
  display: flex;
`;

const ListTitle = styled.h4`
  font-size: 23px;
  margin: 0;
  font-weight: normal;
`;

const ListControl = styled.div`
  display: flex;
  justify-content: space-between;
  .pagination {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
`;

const ListBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  @media (max-width: 651px) {
    display: block;
    margin-top: 0px;
  }
`;

const ListBottomItem = styled.div`
  display: flex;
  justify-content: center;
  &:first-child {
    order: 2;
  }

  &:last-child {
    order: 1;
  }

  @media (max-width: 651px) {
    padding: 16px;
    &:first-child {
      margin: 0 -8px;
      padding: 24px 0;
      background-color: var(--glz-color-neutral);
    }
  }
`;

const ListCardsWrapper = styled.div`
  @media (min-width: 1179px) {
    > :first-child {
      border-top: none;
    }
  }

  @media (max-width: 1180px) {
    margin: 0 -8px;
  }
`;

export {
  ListStyles,
  ListEmptyContent,
  ListEmptyTitle,
  ListEmptyDescription,
  ListHeading,
  ListHeadingItem,
  ListTop,
  ListTitle,
  ListControl,
  ListBottom,
  ListBottomItem,
  ListCardsWrapper,
  ListTopWrapper,
};
