import React from "react";
import PropTypes from "prop-types";
import Checkbox from "ati-ui-react/unstyled/components/Checkbox"; // TODO перейти на новые компоненты
import { commonBoard } from "../../../../../const";
import FormBoardsBadge from "../FormBoardsBadge";
import i18nStrings from "./strings";
import {
  FormBoardsErrorStyles,
  FormBoardsErrorText,
  FormBoardsErrorTextWrapper,
  FormBoardsErrorContainer,
} from "./FormBoardsError.styles";

const FormBoardsError = ({ locale }) => {
  const strings = i18nStrings[locale];
  return (
    <FormBoardsErrorStyles>
      <FormBoardsErrorContainer>
        <Checkbox checked disabled size="small" />
        <FormBoardsBadge board={commonBoard} locale={locale} />
      </FormBoardsErrorContainer>
      <FormBoardsErrorTextWrapper>
        <FormBoardsErrorText>{strings.errorTexts[0]}</FormBoardsErrorText>
        <FormBoardsErrorText>{strings.errorTexts[1]}</FormBoardsErrorText>
      </FormBoardsErrorTextWrapper>
    </FormBoardsErrorStyles>
  );
};

FormBoardsError.defaultProps = {
  locale: "ru",
};

FormBoardsError.propTypes = {
  locale: PropTypes.string,
};

export default FormBoardsError;
