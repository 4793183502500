import styled from "styled-components";

export const GridUserDataContainer = styled.div`
  overflow: hidden;

  .grid-user-data {
    display: flex;
    flex-wrap: wrap;
    margin: 54px 0 0 0;
    padding: ${(props) => (props.showBanners ? "0 15px" : "0")};

    @media (max-width: 400px) {
      display: none;
    }
  }

  .user-data-grid {
    display: flex;
    height: 400px;
    min-width: 340px;
    justify-content: center;
    align-items: center;
    width: calc(33.33% - 28px);
    margin: 0 28px 0 0;
    background: ${(props) => props.theme.colors.color_bg};
    flex-shrink: 0;

    @media (max-width: 800px) {
      margin-bottom: 28px;
    }

    @media (min-width: 1544px) {
      display: none;
    }
  }
`;

export const GridUserDataIframe = styled.iframe`
  max-width: 33.33%;
  min-width: 340px;
  min-height: 400px;

  @media (min-width: 1544px) {
    display: none;
  }
`;
