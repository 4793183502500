import styled from "styled-components";

export const Page = styled.div`
    width: 100%;
    font-family: ${({ theme }) => theme.font};
    color: var(--glz-color-neutral-tone-5);
    background-color: var(--glz-color-neutral-tone-0);
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    @media (max-width: 562px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

export const Content = styled.div`
   .title {
    margin-bottom: 24px;
    @media (max-width: 651px) {
      &.title{
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const Title = styled.h1`
    color: var(--glz-color-neutral-tone-5);
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.48px;
    margin-bottom: 20px;
`;

export const Description = styled.div`
    max-width: 600px;
`;

export const BreadcrumbsWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    z-index: 1;
    @media (max-width: 767px) {
        padding-left: 8px;
    }
    
`;
