import styled from "styled-components";

const ToggleLabelStyles = styled.div`
  margin-bottom: ${(props) => (props.open ? "16px" : "0")};
  font-size: 14px;
  color: ${(props) => (props.primary ? props.theme.colors.primary : "inherit")};
`;

const ToggleLabeInner = styled.div`
  display: block;
  width: 100%;
  cursor: pointer;
  svg path {
    fill: ${(props) => (props.primary && props.theme.colors.primary)};
  }
  &:hover {
    color: ${(props) => props.theme.colors.bostonBlue};
    svg path{
      fill: ${(props) => props.theme.colors.bostonBlue};
    }
  }

  @media (max-width: 651px) {
    margin: -16px 0;
    padding: 16px 8px;
  }
`;

const ToggleLabelText = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const ToggleLabelIcon = styled.span`
  display: inline-flex;
  margin-left: 5px;
  transform: ${(props) => (props.open ? "rotate(0deg)" : "rotate(180deg)")};
  transform-origin: center center;
`;

export {
  ToggleLabelStyles,
  ToggleLabeInner,
  ToggleLabelText,
  ToggleLabelIcon,
};
