import en from "./strings.en";

const ru = {
  errorTexts: {
    0: "Площадки скоро заработают. Обновите страницу через\u00A010\u00A0мин.",
    1: "Пока вы можете искать тендеры только по Общей площадке.",
  },
};

export default { ru, en: { ...ru, ...en } };
