import styled from "styled-components";

export const SubscribePopupWrapper = styled.div`
  *[role="button"] {
    border-bottom: 0!important;
  }
`;

export const SubscribePopupContent = styled.div`
  font-size: 12px;
  margin-bottom: 16px;
`;

export const SubscribePopupDirection = styled.div`
  padding: 12px;
  background-color: ${(props) => props.theme.colors.filter_tab_link};
  margin-bottom: 12px;
  border-radius: 4px;
`;

export const SubscribePopupDirectionItem = styled.div`
  background-color: ${(props) => props.theme.colors.filter_tab_link};
  display: flex;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SubscribePopupDirectionItemLabel = styled.div`
  min-width: 52px;
  margin-right: 12px;
  color: ${(props) => props.theme.colors.color_darkgray};
`;

export const SubscribePopupRow = styled.div`
  display: flex;
  .input-field {
    max-width: 256px;
  }
  .input-field-error {
    color: var(--glz-color-error)
  }
  @media (max-width: 646px) {
    display: block;
  }
`;

export const SubscribePopupInputLabel = styled.span`
  display: inline-block;
  max-width: 128px;
  margin-top: 8px;
  margin-right: 8px;
  @media (max-width: 646px) {
    max-width: 100%;
    margin-bottom: 8px;
    margin-right: 0;
  }
`;

export const SubscribePopupInputDescription = styled.span`
  display: inline-block;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.color_darkgray};
`;

export const SubscribePopupEmail = styled.div`
  width: 100%;
`;
