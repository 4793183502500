import React from "react";
import Skeleton from "ati-ui-react/unstyled/components/Skeleton"; // TODO перейти на новые компоненты
import { BoardsSkeletonStyles } from "./BoardsSkeleton.styles";

const BoardsSkeleton = () => (
  <BoardsSkeletonStyles>
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line skeleton-line--small" />
    <Skeleton className="skeleton-line skeleton-line--large" />
    <Skeleton className="skeleton-line" />
    <Skeleton className="skeleton-line skeleton-line--small" />
  </BoardsSkeletonStyles>
);

export default BoardsSkeleton;
