import styled from "styled-components";

const BoardsSkeletonStyles = styled.div`
  .skeleton-line {
    height: 27px;
    width: 130px;
    margin-bottom: 11px;
    &.skeleton-line--small {
      width: 104px;
    }
    &.skeleton-line--large {
      width: 178px;
    }
  }
`;

export {
  BoardsSkeletonStyles,
};
