import styled from "styled-components";

const HelpLinkStyles = styled.div`
  @media (max-width: 700px) {
    margin-bottom: 16px;
  }
`;

const HelpLinkDesktop = styled.span`
  @media (max-width: 1180px) {
    display: none;
  }
`;

const HelpLinkLessDesktop = styled.span`
  display: none;
  @media (max-width: 1180px) {
    display: inline-block;
  }
`;

const HelpLink = styled.a`
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.color_darkgray};
  text-decoration: none;
  .icon {
    margin-right: 7px;
  }

  .link-text {
    vertical-align: middle;
  }

  &:hover {
    color: ${(props) => props.theme.colors.bostonBlue};
    .icon path{
      fill: ${(props) => props.theme.colors.bostonBlue};
    }
    .youtube-icon path{
      fill: ${(props) => props.theme.colors.youtube_color};
    }
  }
`;

const HelpLinksItem = styled.div`
  display: flex;
  margin-right: 34px;
  &:last-of-type {
    margin-right: 0;
  }
`;

export {
  HelpLinkStyles,
  HelpLinksItem,
  HelpLink,
  HelpLinkDesktop,
  HelpLinkLessDesktop,
};
