import React from "react";
import { observer } from "mobx-react-lite";
import Checkbox from "ati-ui-react/unstyled/components/Checkbox"; // TODO перейти на новые компоненты
import Select from "@components/select";
import Field from "ati-ui-react/unstyled/components/Form/Field"; // TODO перейти на новые компоненты
import InputField from "ati-ui-react/unstyled/components/InputField"; // TODO перейти на новые компоненты
import ToggleLabel from "../../../Common/ToggleLabel";
import Row from "../../../Common/Row";
import Group from "../../../Common/Group";
import { useStore } from "../../../../store/RootStore";
import {
  FormPaymentStyles,
  FormPaymentFields,
  FormPaymentFieldsInner,
} from "./FormPayment.styles";
import i18nStrings from "../strings";

const FormPayment = () => {
  const store = useStore();
  const { payment } = store.formsModule;
  const { onChangeVisibleForm, showForms, dictionariesLoaded, dictionariesServerError } = store.globalModule;
  const { locale } = store.localeModule;
  const {
    onChangeField,
    values,
    currencyOptions,
    betOptions,
    onChangeSummField,
    onBlurField,
    onChangeDelayField,
    onEnter,
  } = payment;
  const { paymentGroup, delay, bet } = values;
  const disabledSelect = !dictionariesLoaded || dictionariesServerError;
  const strings = i18nStrings[locale || "ru"];

  const handleChecked = (group, field, event) => {
    onChangeField(group, field, event.target.checked);
  };

  const handleSelect = (group, field, option) => {
    onChangeField(group, field, option);
  };

  const isOpen = showForms.payment;
  const renderContent = () => {
    if (!isOpen) return null;
    return (
      <>
        <FormPaymentFields className="checkboxes-row">
          <Row label={strings.payment}>
            <div>
              <Checkbox
                checked={paymentGroup.withNDS}
                label={strings.cashlessWithVAT}
                size="small"
                onChange={(event) => handleChecked("paymentGroup", "withNDS", event)}
              />
              <Checkbox
                checked={paymentGroup.withoutNDS}
                label={strings.cashlessWithouVAT}
                size="small"
                onChange={(event) => handleChecked("paymentGroup", "withoutNDS", event)}
              />
              {/* TODO: Вернуть, когда на беке появится поле на беке */}
              {false
                && (
                <Checkbox
                  checked={paymentGroup.cash}
                  label="наличными"
                  size="small"
                  onChange={(event) => handleChecked("paymentGroup", "cash", event)}
                />
)}
            </div>
          </Row>
        </FormPaymentFields>
        <FormPaymentFields className="delay-row">
          <Row label={strings.deferredPayment}>
            <Group>
              <Field className="form-payment-field" size="xsmall">
                <InputField
                  placeholder={strings.from}
                  value={delay.from}
                  onBlur={() => onBlurField("delay")}
                  onChange={(event) => onChangeDelayField("from", event.target.value)}
                  onKeyDown={onEnter}
                />
              </Field>
              <Field className="form-payment-field" size="xsmall">
                <InputField
                  placeholder={strings.upTo}
                  value={delay.to}
                  onBlur={() => onBlurField("delay")}
                  onChange={(event) => onChangeDelayField("to", event.target.value)}
                  onKeyDown={onEnter}
                />
              </Field>
            </Group>
          </Row>
        </FormPaymentFields>
        <FormPaymentFields>
          <Row label={strings.rate}>
            <FormPaymentFieldsInner>
              <Group>
                <Field className="form-payment-field" size="xsmall">
                  <InputField
                    placeholder={strings.from}
                    value={bet.from}
                    onChange={(event) => onChangeSummField("from", event.target.value)}
                    onKeyDown={onEnter}
                  />
                </Field>
                <Field className="form-payment-field" size="xsmall">
                  <Select
                    className="payment-currency-list"
                    isDisabled={disabledSelect}
                    options={currencyOptions}
                    placeholder=""
                    value={bet.currency}
                    onChange={(event) => handleSelect("bet", "currency", event)}
                  />
                </Field>
              </Group>
              <Select
                className="bet-select"
                options={betOptions}
                placeholder=""
                selectedValue={bet.betType.value}
                onChange={(event) => handleSelect("bet", "betType", event)}
              />
              <Checkbox
                checked={bet.prepaid}
                label={strings.prepaid}
                size="small"
                onChange={(event) => handleChecked("bet", "prepaid", event)}
              />
            </FormPaymentFieldsInner>
          </Row>
        </FormPaymentFields>
      </>
    );
  };

  return (
    <FormPaymentStyles>
      <ToggleLabel open={isOpen} onClick={() => onChangeVisibleForm("payment")}>{strings.payment}</ToggleLabel>
      {renderContent()}
    </FormPaymentStyles>
  );
};

export default observer(FormPayment);
