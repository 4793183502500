import en from "./strings.en";

const ru = {
  failedToLoadBoard: "Не удалось загрузить список площадок",
  selectAll: "Выбрать все",
  clear: "Сбросить",
  boards: "Площадки",
  boardsNotAvailable: "Площадки недоступны",
  dangerousGoods: "опасные грузы",
  lengthValue: "Длина",
  after: "м",
  from: "от",
  upTo: "до",
  widthValue: "Ширина",
  heightValue: "Высота",
  dimensionsAndADR: "Габариты и ADR",
  carryingCapacity: "Грузоподъёмность,\u00A0т",
  bodyVolume: "Объём\u00A0кузова,\u00A0м³",
  failedToLoadBodyTypes: "Не удалось загрузить типы кузова",
  bodyTypes: "Тип кузова",
  theOppositeDirection: "Обратное направление",
  whence: "Откуда",
  forExampleMoscow: "Например, Москва",
  where: "Куда",
  forExampleStPetersburg: "Например, Санкт-Петербург",
  status: "Статус",
  view: "Вид",
  iDTheTender: "ID\u00A0тендера",
  additionalParams: "Доп. параметры",
  company: ["фирма", "фирмы", "фирм"],
  noParticipants: "нет участников",
  failedToLoadCompanyLists: "Не удалось загрузить списки фирм",
  searchByCompanyList: "Поиск по спискам фирм",
  hide: "Скрыть",
  createANewList: "Cоздать новый список",
  findACompany: "Найти фирму",
  onlyThisCompany: "Поиск будет только по этой фирме.",
  onlyAvailableToPaid: "Доступно только платным участникам\u00A0ATI.SU.",
  buyLicense: "Купить лицензию",
  searchByCompany: "Поиск по фирмам",
  first: "Сначала",
  payment: "Оплата",
  cashlessWithVAT: "б/нал c НДС",
  cashlessWithouVAT: "б/нал без НДС",
  deferredPayment: "Отсрочка, банк.\u00A0дн.",
  rate: "Ставка",
  prepaid: "с предоплатой",
  cityRegistationCompany: "Город регистрации фирмы",
  allParticipants: "Все Участники ATI.SU участвуют в этой площадке",
};

export default { ru, en: { ...ru, ...en } };
