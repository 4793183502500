const en = {
  inDevelopment: "In development",
  yourOffers: "Your offers",
  tendersAdd: "Add a tender",
  tendersAddMobile: "Add",
  desktopTexts: {
    helpLink: "Guide on how to search for tenders on ATI.SU",
  },
  tabletTexts: {
    helpLink: "Help",
  },
  clearTheForm: "Clear the form",
  searchTheTenders: "Find tenders",
  searchingInTheOppositeDirection: " Searching in the opposite direction",
  allParameters: "All parameters",
  collapse: "Collapse",
};

export default en;
