import styled from "styled-components";

const FormBoardsStyles = styled.div`
  width: 260px;
  @media (max-width: 651px) {
    width: 100%;
  }
  .red-title {
    color: var(--glz-color-error);
  }

  .boards-selector {
    width: 260px;
    height: 196px;
    &.boards-selector label span:before {
      top: 9px;
    }
    label input:checked+span:after {
      top: 12px;
    }
    ul {
      overflow-x: hidden;
    }
  }

  .boards-selector-popup ul {
    margin-top: 20px;
    padding-top: 16px;
  }

  .bords-badge {
    @media (max-width: 651px) {
      max-width: 100%;
      position: relative;
      top: -7px;
      margin-right: 16px;
      margin-bottom: 6px;
    }
  }
`;

const FormBoardsLink = styled.div`
  display: inline-block;
  margin-right: 16px;
  .board-link {
    font-size: 12px;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const FormBoardsLinksContainer = styled.div`
  margin-top: 10px;
`;

export {
  FormBoardsStyles,
  FormBoardsLink,
  FormBoardsLinksContainer,
};
