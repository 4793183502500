import styled from "styled-components";

const FormFirmListStyles = styled.div`
  margin-top: 16px;
  .firm-list-selector {
    height: 212px;
    label > span {
      padding-left: 26px;
    }

    label span:before {
      width: 18px;
      height: 18px;
      top: -1px;
    }

    input:checked + span:after {
      background: url("//files.ati.su/assets/shared/img/checkbox.svg") no-repeat;
      top: 4px;
      left: 3px;
      width: 13px;
      height: 10px;
    }
  }

  .form-list-select {
    position: relative;
    z-index: 3;
  }

  .firm-list-selector-popup {
    z-index: 1000;
  }
  .firm-list-selector-popup ul {
    padding-top: 20px;
  }
`;

const FormFirmListTitle = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  .form-list-title-link {
    font-size: 12px;
    position: relative;
    top: -2px;
  }
`;

const FormFirmListField = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormFirmListAddLink = styled.div`
  .add-link > span > span{
    margin-top: 0;
  }
`;

export {
  FormFirmListStyles,
  FormFirmListTitle,
  FormFirmListField,
  FormFirmListAddLink,
};
