import styled from "styled-components";

const FormFirmsStyles = styled.div`
  width: 259px;
  position: relative;
  z-index: 4;
  @media (max-width: 651px) {
    width: 100%;
  }
`;

const FormFirmsField = styled.div`
  margin-bottom: 16px;
  .form-firms-select {
    position: relative;
    z-index: 100;
  }
`;

const FormFirmsListLink = styled.div`
  font-size: 12px;
  .firms-list-link > span > span {
    margin-top: -2px;
  }
`;

const FormFirmsListErrorMessage = styled.div`
  font-size: 12px;
  line-height: 22px;
  white-space: nowrap;
`;

export {
  FormFirmsStyles,
  FormFirmsField,
  FormFirmsListLink,
  FormFirmsListErrorMessage,
};
