const en = {
  title: "Subscribe to the filter",
  from: "From:",
  to: "To:",
  email: "Email for notifications",
  emailDescription: "Save your time. Receive notifications about new\u00A0tenders.",
  action: "Subscribe",
  cancel: "Cancel",
};

export default en;
