import React from "react";
import PropTypes from "prop-types";
import ArrowIcon from "../../../img/arrow.svg";
import {
  ToggleLabelStyles,
  ToggleLabeInner,
  ToggleLabelText,
  ToggleLabelIcon,
} from "./ToggleLabel.styles";

const ToggleLabel = ({ children, open, onClick, primary, className }) => (
  <ToggleLabelStyles className={className} open={open} primary={primary}>
    <ToggleLabeInner primary={primary} onClick={(event) => onClick(!open, event)}>
      <ToggleLabelText>{children}</ToggleLabelText>
      <ToggleLabelIcon open={open}>
        <img alt="" src={ArrowIcon} />
      </ToggleLabelIcon>
    </ToggleLabeInner>
  </ToggleLabelStyles>
);

ToggleLabel.defaultProps = {
  open: true,
  onClick: () => { },
  primary: false,
  className: "",
};

ToggleLabel.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ToggleLabel;
