import styled from "styled-components";

const FirmListOptionStyles = styled.div`
  margin-bottom: 8px;
  margin-right: 32px;
`;

const FirmListOptionName = styled.div`
  font-size: 12px;
`;

const FirmListOptionEmoji = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const FirmListOptionIcon = styled.div`
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -1px;
`;

export {
  FirmListOptionStyles,
  FirmListOptionName,
  FirmListOptionEmoji,
  FirmListOptionIcon,
};
