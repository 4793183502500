import en from "./strings.en";

const ru = {
  inDevelopment: "В разработке",
  yourOffers: "Ваши предложения",
  tendersAdd: "Добавить тендер",
  tendersAddMobile: "Добавить",
  desktopTexts: {
    helpLink: "Инструкция по поиску тендеров на ATI.SU",
  },
  tabletTexts: {
    helpLink: "Помощь",
  },
  clearTheForm: "Очистить форму",
  searchTheTenders: "Найти тендеры",
  searchingInTheOppositeDirection: " Поиск в обратном направлении",
  allParameters: "Все параметры",
  collapse: "Свернуть",
};

export default { ru, en: { ...ru, ...en } };
