import React from "react";
import { observer } from "mobx-react-lite";
import Select from "@components/select"; // TODO перейти на новые компоненты
import Row from "../../../Common/Row";
import { useStore } from "../../../../store/RootStore";
import {
  FormOutputStyles,
} from "./FormOutput.styles";
import i18nStrings from "../strings";

const FormOutput = () => {
  const store = useStore();
  const { values, outputOptions, onChangeFieldOutput } = store.formsModule.output;
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale || "ru"];

  return (
    <FormOutputStyles>
      <Row label={strings.first}>
        <Select
          className="form-output-select"
          options={outputOptions}
          selectedValue={values.output.select.value}
          onChange={(option) => onChangeFieldOutput("output", "select", option)}
        />
      </Row>
    </FormOutputStyles>
  );
};

export default observer(FormOutput);
