import en from "./strings.en";

const ru = {
  showTenders: "Выводить\u00A0тендеров",
  subscribeToNewTendersOnATISU: "Подпишитесь на новые тендеры на ATI.SU",
  subscribeToNewTendersForThisDirection: "Подпишитесь на новые тендеры по этому направлению",
  subcribe: "Подписаться",
  subscribeToNewTenders: "Подписаться на новые тендеры",
  tendersATI: "Тендеры ATI.SU",
  messengerATI: "АТИ Мессенджер",
  telegram: "Телеграм",
  subscribeToTheChannel: "Подпишитесь на канал с\u00A0новыми тендерами",
  // eslint-disable-next-line max-len
  hostsSeveralTrucking: "На ATI.SU ежедневно запускается несколько тендеров на грузоперевозки. Подпишитесь на канал с новыми тендерами, чтобы не пропустить новый крупный контракт по вашему направлению.",
  channelWithNewTenders: "Каналы с новыми тендерами",
  tenderFound: ["тендер найден", "тендера найдено", "тендеров найдено"],
  tendersNotFound: "Тендеры не найдены",
  subscribeToTenders: "Подпишитесь на новые тендеры, чтобы\u00A0узнавать о\u00A0начале крупных конкурсов\u00A0на\u00A0грузоперевозки",
};

export default { ru, en: { ...ru, ...en } };
