import React from "react";
import PropTypes from "prop-types";
import Icon from "@components/icon";
import Link from "@components/link";
import {
  LoadErrorStyles,
  LoadErrorIcon,
} from "./LoadError.styles";
import strings from "../strings";
import { useStore } from "../../../store/RootStore";

const CarTypesError = ({ onClick, children }) => {
  const store = useStore();
  const { locale } = store.localeModule;
  return (
    <LoadErrorStyles>
      <LoadErrorIcon>
        <Icon
          fillRule="evenodd"
          height={24}
          name="ic-alert"
          viewBox="0 0 24 21"
          width={24}
        />
      </LoadErrorIcon>
      <span>{children}</span>
      <Link
        className="car-types-error-link"
        mode="pseudo"
        onClick={onClick}
      >
        {strings[locale].repeat}
      </Link>
    </LoadErrorStyles>
  );
};

CarTypesError.defaultProps = {
  onClick: () => { },
  children: "",
};

CarTypesError.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default CarTypesError;
