import styled from "styled-components";

export const ResultListControlStyles = styled.div`
  background: ${(props) => props.theme.colors.filter_color};
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  @media (max-width: 1179px) {
    margin-bottom: 8px;
    padding: 14px 16px;
  }

  @media (max-width: 651px) {
    margin-bottom: 8px;
    padding: 15px 16px;
  }

  @media (max-width: 768px) {
    display: block;
  }

`;

export const ContentStyles = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  .icon-bell>span {
    display: flex;
  }
`;

export const TextStyles = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-left: 16px;
`;

export const SourceButtonsStyles = styled.div`
  margin-right: 32px;
  max-width: 276px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;
