import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Heading from "@components/heading";
import Head from "next/head";
import dynamic from "next/dynamic";
import { configure } from "mobx";
import Layout from "../Layout";
import {
  MainWrapper,
  TendersWrapper,
} from "./TenderSeach.styles";
import i18nStrings from "./strings";
import FilterTabs from "../Content/Filter/FilterTabs/FilterTabs";
import { useStore } from "../../store/RootStore";
import { TendersSearchProps } from "../../types";
import ContentTenderSeach from "./ContentTenderSeach";

configure({
  isolateGlobalState: true,
});

const TenderSeach = ({
  userId,
  locale,
  hasAtiWithoutAdsLicence,
  isAtiApp,
  account,
  hasTenderLicense,
  activeLicenses,
  query,
  contactId,
  domain,
}: TendersSearchProps) => {
  const store = useStore();
  const { globalModule, URLQueryModule } = store;
  const strings = i18nStrings[locale || "ru"];

const Sidebar = dynamic(() => import("../Common/Sidebar"), { ssr: false });

  const initionalData = {
    account,
    hasTenderLicense,
    isAtiApp,
    hasAtiWithoutAdsLicence,
    userId,
    activeLicenses,
    contactId,
  };

  const renderHead = () => (
    <Head>
      <meta content={strings.description} name="description" />
      <title>{strings.title}</title>
    </Head>
  );

  useEffect(() => {
    globalModule.init(initionalData);
    URLQueryModule.init(query);
  }, [account]);

  return (
    <>
      {renderHead()}
      <MainWrapper>
        <Layout className="layout" domain={domain} locale={locale}>
          <TendersWrapper>
            <Heading className="title" size={1}>{strings.tendersForRoadTransportation}</Heading>
            <FilterTabs />
            <ContentTenderSeach
              domain={domain}
              hasAtiWithoutAdsLicence={hasAtiWithoutAdsLicence}
              isAtiApp={isAtiApp}
              locale={locale}
            />
          </TendersWrapper>
        </Layout>
      </MainWrapper>
      {locale !== "en" && <Sidebar isAtiApp={isAtiApp} showBanners={!hasAtiWithoutAdsLicence} />}
    </>
  );
};

export default observer(TenderSeach);
