import React from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import QuickFirmsSearchUI from "ati-ui-react/unstyled/widgets/QuickFirmsSearch"; // TODO перейти на новые компоненты
import { useStore } from "../../../../../store/RootStore";
import {
  QuickFirmsSearchStyles,
  QuickFirmsSearchWarning,
} from "./QuickFirmsSearch.styles";
import i18nStrings from "../../strings";

const QuickFirmsSearch = () => {
  const store = useStore();
  const {
    values,
    showFirmWarning,
    onSuggestionFirmSelected,
    openExtendedSearchPopup,
    firmSuggestion,
    getFirmsSuggestions,
    onChangeFirmField,
    clearFirmSuggestion,
  } = store.formsModule.firms;
  const { firm } = values;
  const { locale } = store.localeModule;

  const strings = i18nStrings[locale || "ru"];

  const disabled = Boolean(firm.optionSelector.length);

  const getClassName = () => {
    if (showFirmWarning) return "firm-search-wrapper-warning";
    if (disabled) return "firm-search-wrapper-disabled";
    return "";
  };

  const handleSuggestion = () => {
    getFirmsSuggestions();
  };

  const handleOpenExtendedSearch = () => {
    openExtendedSearchPopup();
  };

  return (
    <QuickFirmsSearchStyles>
      <QuickFirmsSearchUI
        noFog
        withFooter
        inputProps={{
          disabled,
        }}
        placeholder={strings.findACompany}
        searchProperty="name_rus" // TODO
        suggestions={toJS(firmSuggestion)}
        value={firm.autosuggest}
        valueProperty="name_rus" // TODO
        wrapperClassName={`firm-search-wrapper ${getClassName()}`}
        onBlur={clearFirmSuggestion}
        onChange={(event) => onChangeFirmField(event)}
        onOpenExtendedSearch={handleOpenExtendedSearch}
        onSuggestionSelected={onSuggestionFirmSelected}
        onSuggestionsFetchRequested={handleSuggestion}
      />
      { showFirmWarning
        && <QuickFirmsSearchWarning>{strings.onlyThisCompany}</QuickFirmsSearchWarning>}
    </QuickFirmsSearchStyles>
  );
};

export default observer(QuickFirmsSearch);
