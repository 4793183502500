import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Icon from "@components/icon";
import Preloader from "@components/preloader";
import declension from "ati-core-utils/declension";
import interpolate from "ati-core-utils/interpolate";
import FilterSubscribeDeletePopover from "../FilterSubscribeDeletePopover";
import caseIcon from "../../../../../img/case.svg";
import { useStore } from "../../../../../store/RootStore";
import i18nStrings from "./strings";
import {
  FilterSubscribePageStyles,
  FilterSubscribePageEmpty,
  FilterSubscribePageEmptyInner,
  FilterSubscribePageEmptyTitle,
  FilterSubscribePageContent,
  FilterSubscribePageCount,
  FilterSubscribePageList,
  FilterSubscribePageItem,
  FilterSubscribePageItemEmail,
  FilterSubscribePageRoute,
  FilterSubscribePageRouteWrapper,
  FilterSubscribePageDelete,
  FilterSubscribePageLoader,
} from "./FilterSubscribePage.styles";
import getCurrentGeoType from "../../../../../utils/getCurrentGeoType";

const FilterSubscribePage = () => {
  const store = useStore();
  const { subscriptions, geoData, loadingGeoData, goToFilter, deleteFilterSubscriptions } = store.filterSubscribeModule;
  const { locale } = store.localeModule;
  const showEmpty = !subscriptions.length;
  const strings = i18nStrings[locale];
  const [visibleDeleteTooltipId, setVisibleDeleteTooltipId] = useState(null);

  const getDirectionString = (direction) => {
    if (!Object.keys(geoData).length) return "";
    if (!direction) return "";
    const currentGeoType = getCurrentGeoType(direction.geoType);

    return geoData[currentGeoType][direction.geoId]?.name || "";
  };

  const renderRoutes = (routes) => {
    const routesFrom = routes.find((route) => route.direction === 0);
    const routesTo = routes.find((route) => route.direction === 1);

    const routeFromStr = getDirectionString(routesFrom);
    const routeToStr = getDirectionString(routesTo);
    const result = [routeFromStr, routeToStr].filter(Boolean).join("\u00A0-> ");

    return {
      text: result,
      fromText: routeFromStr,
      toText: routeToStr,
      fromRoute: routesFrom,
      toRoute: routesTo,
    };
  };

  const handleDelete = (id) => {
    setVisibleDeleteTooltipId(id);
  };

  const renderSubscriptionsList = () => subscriptions.map((subscription) => {
    const routes = renderRoutes(subscription.routes);

    return (
      <FilterSubscribePageItem key={subscription.id}>
        <FilterSubscribePageRouteWrapper>
          <FilterSubscribePageRoute onClick={() => goToFilter(subscription, routes)}>
            {routes.text}
          </FilterSubscribePageRoute>
          <FilterSubscribePageDelete
            className={visibleDeleteTooltipId === subscription.id ? "is-deleted" : ""}
            onClick={() => handleDelete(subscription.id)}
          >
            <FilterSubscribeDeletePopover
              locale={locale}
              visible={visibleDeleteTooltipId === subscription.id}
              onClose={(event) => {
                event.stopPropagation();
                setVisibleDeleteTooltipId(null);
              }}
              onDelete={() => deleteFilterSubscriptions(subscription.id)}
            >
              <Icon color="var(--glz-color-neutral-tone-4)" height="16" name="ic_delete" width="16" />
            </FilterSubscribeDeletePopover>
          </FilterSubscribePageDelete>
        </FilterSubscribePageRouteWrapper>
        <FilterSubscribePageItemEmail>
          {subscription.email}
        </FilterSubscribePageItemEmail>
      </FilterSubscribePageItem>
    );
  });

  const renderSubscriptionsContent = () => {
    const plural = declension(subscriptions.length, strings.countTextPlural);
    return (
      <FilterSubscribePageContent>
        {!loadingGeoData
          && (
          <FilterSubscribePageCount>
            {interpolate(strings.countText, {
              count: subscriptions.length,
              plural,
            })}
          </FilterSubscribePageCount>
          )}
        <FilterSubscribePageList>
          {!loadingGeoData && renderSubscriptionsList()}
          {loadingGeoData && (
            <FilterSubscribePageLoader>
              <Preloader small />
            </FilterSubscribePageLoader>
          )}
        </FilterSubscribePageList>
      </FilterSubscribePageContent>
    );
  };

  const renderEmpty = () => (
    <FilterSubscribePageEmpty>
      <FilterSubscribePageEmptyInner>
        <img alt="" src={caseIcon} />
        <FilterSubscribePageEmptyTitle>{strings.empty.title}</FilterSubscribePageEmptyTitle>
        <span>{strings.empty.description}</span>
      </FilterSubscribePageEmptyInner>
    </FilterSubscribePageEmpty>
  );

  return (
    <FilterSubscribePageStyles>
      {showEmpty && renderEmpty()}
      {!showEmpty && renderSubscriptionsContent()}
    </FilterSubscribePageStyles>
  );
};

export default observer(FilterSubscribePage);
