import React from "react";
import PropTypes from "prop-types";
import Icon from "@components/icon";
import Tooltip from "../../../../Common/Tooltip";
import { FormBoardsInfoIcon, FormBoardsBadgeStyles } from "./FormBoardsBadge.styles";
import i18nstrings from "../../strings";

const FormBoardsBadge = (props) => {
  const { board, locale, className } = props;
  const strings = i18nstrings[locale || "ru"];

  const renderInfoIcon = (boardId, tooltipText) => (
    <FormBoardsInfoIcon data-tip data-for={`board-tooltip-${boardId}`}>
      <Tooltip id={`board-tooltip-${boardId}`} marginTop={-10}>
        <span>{tooltipText}</span>
      </Tooltip>
      <Icon
        color="var(--glz-color-neutral-tone-2)"
        fillRule="evenodd"
        height={14}
        name="ic_question-out"
        viewBox="0 0 24 24"
        width={14}
      />
    </FormBoardsInfoIcon>
  );

  return (
    <FormBoardsBadgeStyles bgColor={board.bgColor} className={className} color={board.color} key={board.id}>
      {board.shortName}
      {board.isCommon && renderInfoIcon(board.id, strings.allParticipants)}
    </FormBoardsBadgeStyles>
  );
};

FormBoardsBadge.defaultProps = {
  board: {},
  locale: "ru",
  className: "",
};

FormBoardsBadge.propTypes = {
  board: PropTypes.object,
  className: PropTypes.string,
  locale: PropTypes.string,
};

export default FormBoardsBadge;
