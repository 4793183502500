import styled from "styled-components";

const FaqLinkStyles = styled.span`
  margin-left: auto;
  .link_container .link span {
    color: ${(props) => props.theme.colors.color_gray};
    path {
      fill: ${(props) => props.theme.colors.color_gray};
    }
  }

  .link__icon {
    position: relative;
    top: -1px;
  }

  .left-content {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
`;

export {
  FaqLinkStyles,
};
