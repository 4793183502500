import React from "react";
import PropTypes from "prop-types";
import Popover from "@components/popover";
import Button from "@components/button";
import Link from "@components/link";
import i18nStrings from "./strings";
import {
  FilterSubscribePopoverWrapper,
} from "./FilterSubscribeDeletePopover.styles";

const FilterSubscribeDeletePopover = (props) => {
  const { children, locale, onClose, onDelete, visible, onVisibilityChange } = props;
  const strings = i18nStrings[locale];

  return (
    <FilterSubscribePopoverWrapper>
      <Popover
        isClosedOnOutsideClick
        classNames="popover-wrapper"
        content={(
          <>
            <Popover.Header>{strings.title}</Popover.Header>
            <Popover.Footer className="popover-footer">
              <Popover.ActionWrapper>
                <Button mobile size="small" onClick={onDelete}>
                  {strings.button}
                </Button>
              </Popover.ActionWrapper>
              <Popover.ActionWrapper>
                <Link className="delete-popover-link" type="pseudo" onClick={onClose}>
                  {strings.link}
                </Link>
              </Popover.ActionWrapper>
            </Popover.Footer>
          </>
        )}
        size="small"
        theme="light"
        visible={visible}
        onCloseClick={onClose}
        onVisibilityChange={onVisibilityChange}
      >
        {children}
      </Popover>
    </FilterSubscribePopoverWrapper>
  );
};

FilterSubscribeDeletePopover.defaultProps = {
  locale: "ru",
  children: null,
  onClose: () => {},
  onDelete: () => { },
  onVisibilityChange: () => { },
  visible: false,
};

FilterSubscribeDeletePopover.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onVisibilityChange: PropTypes.func,
};

export default FilterSubscribeDeletePopover;
