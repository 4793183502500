import styled from "styled-components";

const TooltipStyles = styled.div`
  .custom-tooltip {
      border-radius: 2px !important;
      padding: 5px 10px 5px !important;
      font-size: 11px !important;
      line-height: 14px !important;
      font-weight: normal !important;
      max-width: 240px;
      text-align: center !important;
      margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0")} !important;
      &.place-top:before {
        height: auto !important;
      }
      &.place-top:after {
        border-top-width: 5px !important;
        bottom: -3px !important;
        margin-left: -5px !important;
      }
    }
`;

export {
  TooltipStyles,
};
