import styled from "styled-components";

const OptionSkeletonStyles = styled.div`
  .skeleton-line {
    margin-bottom: 11px;
    width: 98px;
    height: 12px;
    border-radius: 4px;
    margin-left: 20px;
    &.skeleton-line--base {
      width: 144px;
      margin-left: 0;
    }
  }
`;

export {
  OptionSkeletonStyles,
};
