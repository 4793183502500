import React from "react";
import { observer } from "mobx-react-lite";
import Button from "ati-ui-react/unstyled/components/Button"; // TODO перейти на новые компоненты
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import DropdownMenuButton from "ati-ui-react/unstyled/widgets/DropdownMenuButton"; // TODO перейти на новые компоненты
import FilterSubscribeLink from "../FilterSubscribe/FilterSubscribeLink";
import { useStore } from "../../../../store/RootStore";
import {
  SearchControlStyles,
  SearchControlButton,
  SearchControlLink,
} from "./SearchControl.styles";
import i18nStrings from "../strings";

const SearchControl = () => {
  const store = useStore();
  const { showClearFilterButton, clearFilter, onSearch, searchLoading } = store.globalModule;
  const { locale } = store.localeModule;
  const strings = i18nStrings[locale || "ru"];
  return (
    <SearchControlStyles>
      {showClearFilterButton
        && (
        <SearchControlLink>
          <Link
            gray
            className="link-element"
            type="pseudo"
            onClick={clearFilter}
          >
            {strings.clearTheForm}
          </Link>
        </SearchControlLink>
)}
      <SearchControlLink className="subscribe-link">
        <FilterSubscribeLink />
      </SearchControlLink>
      <SearchControlButton>
        <Button
          noBorderRight
          primary
          isLoading={searchLoading}
          mobile={false}
          size="default"
          onClick={() => onSearch({ initial: false })}
        >
          {strings.searchTheTenders}
        </Button>
        <DropdownMenuButton
          arrowWidth
          noBorderLeft
          menuActions={[
            {
              name: strings.searchingInTheOppositeDirection,
              handleClick: () => onSearch({ reverse: true }),
            },
          ]}
          size="default"
        />
      </SearchControlButton>
    </SearchControlStyles>
  );
};

export default observer(SearchControl);
