import React from "react";
import Link from "@components/link";
import Icon from "@components/icon";
import { BreadcrumbsWrapper } from "./BreadcrumbsComponent.styles";
import i18nStrings from "../strings";

const BreadcrumbsComponent = ({ locale }) => {
  const strings = i18nStrings[locale || "ru"];

  return (
    <BreadcrumbsWrapper>
      <Link
        as="a"
        before={<Icon color="var(--glz-color-neutral-tone-3)" name="ic_arrow-left-bold" size="m" />}
        className="breadcrumbs-link"
        href="/"
        target="_blank"
      >
        {strings.home}
      </Link>
    </BreadcrumbsWrapper>
  );
};

export default BreadcrumbsComponent;
