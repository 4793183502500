import styled from "styled-components";

const FormCarTypesStyles = styled.div`
  width: 260px;
  @media (max-width: 651px) {
    width: 100%;
  }
  .cargo-types-clear {
    margin-top: 16px;
    display: inline-block;
    font-size: 12px;
  }
  .cargo-types-selector {
    width: 260px;
    height: 196px;
  }
`;

export {
  FormCarTypesStyles,
};
