import styled from "styled-components";

export const FilterSubscribePageStyles = styled.div`
  background-color: ${(props) => props.theme.colors.filter_color};
  box-shadow: 0px 2px 0px #dbdbdb;
  border-radius: 0px 0px 8px 8px;
  min-height: 326px;
`;

export const FilterSubscribePageEmpty = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${(props) => props.theme.colors.color_bluegray};
`;

export const FilterSubscribePageEmptyInner = styled.div`
  padding-top: 74px;
  padding-bottom: 20px;
  max-width: 350px;
  margin: 0 auto;
`;

export const FilterSubscribePageEmptyTitle = styled.span`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
`;

export const FilterSubscribePageContent = styled.div`
  padding: 24px;
  font-size: 12px;
  position: relative;
`;

export const FilterSubscribePageCount = styled.span`
  display: block;
  margin-bottom: 8px;
`;

export const FilterSubscribePageItemEmail = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.color_darkgray};
  margin-top: 2px;
`;

export const FilterSubscribePageItem = styled.div`
  padding: 8px 12px;
`;

export const FilterSubscribePageList = styled.div`
  background-color: ${(props) => props.theme.colors.color_white};
  min-height: 264px;
  border: 1px solid #d9d9d9;
`;

export const FilterSubscribePageRoute = styled.a`
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.color_blue_hover};
  }
`;

export const FilterSubscribePageDelete = styled.div`
  margin-left: 8px;
  cursor: pointer;
  display: inline-block;
  visibility: visible;
  position: relative;
  top: -2px;
  &.is-deleted {
    visibility: visible;
  }
  @media (hover: none) {
    visibility: visible;
  }
  @media (max-width: 768px) {
    visibility: visible;
  }
`;

export const FilterSubscribePageRouteWrapper = styled.div`
  display: flex;
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 24px;
  &:hover {
    ${FilterSubscribePageDelete} {
      visibility: visible;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const FilterSubscribePageLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
