import styled from "styled-components";

const FormDirectionStyles = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 651px) {
    display: block;
  }
`;

const FormDirectionField = styled.div`
  width: 100%;
  input {
    height: 38px;
  }
  label div div {
    line-height: 38px;
  }

  &:first-child {
    @media (max-width: 651px) {
      margin-bottom: 16px;
    }
  }
`;

const FormDirectionLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 6px;
`;

const FormDirectionIcon = styled.div`
  min-width: 40px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .direction-icon {
    display: inline-block;
    margin-bottom: 11px;
    cursor: pointer;
    width: 18px;
    height: 14px;
  }
  @media (max-width: 651px) {
    display: none;
  }
`;

export {
  FormDirectionStyles,
  FormDirectionField,
  FormDirectionLabel,
  FormDirectionIcon,
};
