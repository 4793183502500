import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import Select from "@components/select"; // TODO перейти на новые компоненты
import InputField from "ati-ui-react/unstyled/components/InputField"; // TODO перейти на новые компоненты
import ToggleLabel from "../../../Common/ToggleLabel";
import Row from "../../../Common/Row";
import { useStore } from "../../../../store/RootStore";
import {
  FormExtraStyles,
  FormExtraField,
} from "./FormExtra.styles";
import i18nStrings from "../strings";

const FormExtra = () => {
  const store = useStore();
  const { showForms, onChangeVisibleForm } = store.globalModule;
  const { locale } = store.localeModule;
  const { extra } = store.formsModule;
  const { onChangeField, values, statusOptions, viewsOptions, onChangeTenderID, onEnter } = extra;
  const { status, views, tenderId } = values;

  const isOpen = showForms.extra;
  const strings = i18nStrings[locale || "ru"];

  const handleSelect = (group, field, option) => {
    onChangeField(group, field, option);
  };

  const renderContent = () => {
    if (!isOpen) return null;
    return (
      <>
        <FormExtraField>
          <Row label={strings.status}>
            <Select
              options={statusOptions}
              selectedValue={status.select.value}
              onChange={(event) => handleSelect("status", "select", event)}
            />
          </Row>
        </FormExtraField>
        {/* TODO: Вернуть, когда появится поле на беке */}
        {false && (
        <FormExtraField>
          <Row label={strings.view}>
            <Select
              options={viewsOptions}
              selectedValue={views.select.value}
              onChange={(event) => handleSelect("views", "select", event)}
            />
          </Row>
        </FormExtraField>
)}
        <FormExtraField>
          <Row className="form-extra-id" label={strings.iDTheTender}>
            <InputField
              value={tenderId.input}
              onChange={(event) => onChangeTenderID(event.target.value)}
              onKeyDown={onEnter}
            />
          </Row>
        </FormExtraField>
      </>
    );
  };

  return (
    <FormExtraStyles>
      <ToggleLabel open={isOpen} onClick={() => onChangeVisibleForm("extra")}>{strings.additionalParams}</ToggleLabel>
      {renderContent()}
    </FormExtraStyles>
  );
};

export default observer(FormExtra);
