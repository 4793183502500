import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import i18nStrings from "./strings";
import { useStore } from "../../../../../store/RootStore";
import { FilterSubscribeInfoStyles } from "./FilterSubscribeInfo.styles";
import addMetric from "../../../../../utils/addMetric";

const FilterSubscribeInfo = ({ locale }) => {
  const store = useStore();
  const strings = i18nStrings[locale];
  const {
    showFilterInfo,
  } = store.filterSubscribeModule;

  if (!showFilterInfo) return false;
  addMetric("tender-subscribe-promo");

  return (
    <FilterSubscribeInfoStyles>
      {strings.subscribeToFilterText}
    </FilterSubscribeInfoStyles>
  );
};

FilterSubscribeInfo.defaultProps = {
  locale: "ru",
};

FilterSubscribeInfo.propTypes = {
  locale: PropTypes.string,
};

export default observer(FilterSubscribeInfo);
