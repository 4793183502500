import styled from "styled-components";

const FormPaymentStyles = styled.div`
  position: relative;
  z-index: 10;
  width: 227px;
  .checkboxes-row {
    margin-bottom: 16px;
    .custom-row-label {
      margin-top: 0;
    }
  }
  .delay-row {
    margin-bottom: 20px;
  }

  .bet-select {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  @media (max-width: 651px) {
    width: 100%;
    .form-payment-field {
      max-width: 100%;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }


`;

const FormPaymentFields = styled.div`
  margin-bottom: 8px;
`;

const FormPaymentFieldsInner = styled.div`
  width: 100%;
`;

export {
  FormPaymentStyles,
  FormPaymentFields,
  FormPaymentFieldsInner,
};
