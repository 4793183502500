import React from "react";
import HelpLinks from "../HelpLinks";
import SearchControl from "../SearchControl";
import FilterSubscribeLink from "../FilterSubscribe/FilterSubscribeLink";
import {
  FilterBottomStyles,
  FilterBottomCell,
  FilterBottomSubscribeWrapper,
} from "./FilterBottom.styles";

const FilterBottom = ({ domain }) => (
  <FilterBottomStyles>
    <FilterBottomCell>
      <HelpLinks domain={domain} />
      <FilterBottomSubscribeWrapper>
        <FilterSubscribeLink />
      </FilterBottomSubscribeWrapper>
    </FilterBottomCell>
    <FilterBottomCell>
      <SearchControl />
    </FilterBottomCell>
  </FilterBottomStyles>
);

export default FilterBottom;
