import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import OptionsSelector from "ati-ui-react/unstyled/components/OptionsSelector"; // TODO перейти на новые компоненты
import withCarTypes from "ati-ui-react/HOC/withCarTypes"; // TODO перейти на новые компоненты
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import ToggleLabel from "../../../Common/ToggleLabel";
import LoadError from "../../../Common/LoadError";
import SkeletonContainer from "../../../Common/SkeletonContainer";
import OptionSkeleton from "../../../Common/OptionSkeleton";
import { useStore } from "../../../../store/RootStore";
import { FormCarTypesStyles } from "./FormCarTypes.styles";
import i18nStrings from "../strings";

const CarOptionsSelector = withCarTypes(OptionsSelector);

const FormCarTypes = () => {
  const store = useStore();
  const { carTypes } = store.formsModule;
  const {
    onChangeVisibleForm,
    showForms,
    dictionaries,
    dictionariesLoaded,
    dictionariesServerError,
    getDictionariesRequest,
  } = store.globalModule;
  const { locale } = store.localeModule;
  const { onChangeField, values, clearForm, showClearButton } = carTypes;

  const isOpen = showForms.carTypes;
  const dictionary = dictionaries.CarTypes || [];
  const strings = i18nStrings[locale || "ru"];

  const handleSelected = (event) => {
    onChangeField("carTypes", "optionSelector", event);
  };

  const renderContent = () => {
    if (!isOpen) return null;
    if (dictionariesServerError) {
      return (
        <SkeletonContainer>
          <LoadError onClick={getDictionariesRequest}>
            {strings.failedToLoadBodyTypes}
          </LoadError>
        </SkeletonContainer>
      );
    }
    if (!dictionariesLoaded) {
      return (
        <SkeletonContainer>
          <OptionSkeleton />
        </SkeletonContainer>
      );
    }
    return (
      <>
        <CarOptionsSelector
          className="cargo-types-selector"
          clearAllSelectedValues={clearForm}
          dictionary={dictionary}
          labelProperty="name"
          locale={locale}
          selectedValues={values.carTypes.optionSelector}
          onChange={handleSelected}
        />
        {showClearButton && (
        <Link
          className="cargo-types-clear"
          type="pseudo"
          onClick={clearForm}
        >
          {strings.clear}
        </Link>
)}
      </>
    );
  };

  return (
    <FormCarTypesStyles>
      <ToggleLabel open={isOpen} onClick={() => onChangeVisibleForm("carTypes")}>
        {strings.bodyTypes}
      </ToggleLabel>
      {renderContent()}
    </FormCarTypesStyles>
  );
};

export default observer(FormCarTypes);
