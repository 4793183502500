import React from "react";
import Icon from "@components/icon";
import Link from "@components/link";
import { FaqLinkStyles } from "./FaqLink.styles";
import i18nStrings from "../strings";

const FaqLink = ({ locale, domain }) => {
    const strings = i18nStrings[locale || "ru"];
    return (
      <FaqLinkStyles>
        <Link
          as="a"
          className="left-content"
          href={`//trace.${domain}`}
          theme="gray"
        >
          <Icon
            color="var(--glz-color-neutral-tone-3)"
            name="ic_map-pin"
            size="m"
          />
          {strings.distanceCalculation}
        </Link>
      </FaqLinkStyles>

    );
};

export default FaqLink;
