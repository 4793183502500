import React from "react";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import FaqLink from "./FaqLink";
import { TopNavigateStyles } from "./TopNavigate.styles";

const TopNavigate = ({ locale, domain }) => (
  <TopNavigateStyles>
    <BreadcrumbsComponent locale={locale} />
    <FaqLink domain={domain} locale={locale} />
  </TopNavigateStyles>
);

export default TopNavigate;
