import styled from "styled-components";

const TopNavigateStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 37px;
  @media (max-width: 1180px){
    padding: 16px 8px 16px;
  }
  @media (max-width: 561px){
    padding: 16px 8px 10px;
  }
`;

export {
  TopNavigateStyles,
};
