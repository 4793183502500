import styled from "styled-components";

const FormOutputStyles = styled.div`
  min-width: 230px;
  z-index: 2;
  .custom-row-label {
    min-width: 60px;
    max-width: 88px;
  }

  .form-output-select {
    max-width: 190px;
  }
  @media (max-width: 525px) {
    margin-top: 20px;
  }
`;

export {
  FormOutputStyles,
};
