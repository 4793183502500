import styled from "styled-components";

export const FilterTabsStyles = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100px;
  @media (max-width: 651px) {
    height: 90px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 43px;
      width: 100%;
      height: 1px;
      background: var(--glz-color-neutral-tone-3);
      z-index: 1;
    }
  }
`;

export const FilterTabsList = styled.ul`
  width: 100%;
  margin: 0 0 -30px;
  padding: 0 0 30px;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  list-style: none;
  overflow-x: auto;
  position: relative;
`;

export const FilterTabsItem = styled.a`
  position: relative;
  padding: 16px 24px;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  .tab-tooltip {
    display: flex;
    white-space: normal;
  }

  @media (max-width: 651px) {
    padding: 10px 16px;
    font-size: 14px
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--glz-color-neutral-tone-3);
  }

  &.active {
    cursor: default;
    background-color: ${(props) => props.theme.colors.filter_color};
    @media (max-width: 651px) {
      background-color: transparent;
      &:after {
        content: "";
        position: absolute;
        left: -1px;
        top: 43px;
        width: calc(100% + 2px);
        height: 4px;
        background: var(--glz-color-primary);
        z-index: 1;
      }
    }
  }

  .tab-link-counter {
    display: inline-block;
    background: var(--glz-color-stars-bad);
    color: var(--glz-color-neutral-tone-0);
    border-radius: 11px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bolder;
    padding: 1px;
    width: 28px;
    text-align: center;
    margin-left: 8px;
  }
`;

export const FilterTabsItemText = styled.span`
  white-space: nowrap;
`;

export const FilterTabsLink = styled.a`
  padding: 16px 24px;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  line-height: 24px;
  background-color: var(--glz-color-primary-tone-3);
  cursor: pointer;
  margin-left: auto;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  &:hover {
    background-color: var(--glz-color-primary-tone-3);
    opacity: 0.8;
  }
  @media (max-width: 651px) {
    padding: 10px 16px;
    font-size: 14px;
  }
  .tab-link-text {
    vertical-align: middle;
  }
  .tab-link-counter {
    display: inline-block;
    background: var(--glz-color-stars-bad);
    color: var(--glz-color-neutral-tone-0);
    border-radius: 11px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bolder;
    padding: 1px;
    width: 28px;
    text-align: center;
    margin-left: 8px;
  }
`;
