import styled from "styled-components";

const LoadErrorStyles = styled.div`
  width: 160px;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  margin-top: 40px;
  .car-types-error-link {
    display: block;
    font-size: 12px;
    margin-top: 6px;
  }
`;

const LoadErrorIcon = styled.div`
  margin-bottom: 6px;
  svg path {
    fill: var(--glz-color-neutral-tone-4);
  }
`;

export {
  LoadErrorStyles,
  LoadErrorIcon,
};
