import React from "react";
import { observer } from "mobx-react-lite";
import OptionsSelector from "ati-ui-react/unstyled/components/OptionsSelector"; // TODO перейти на новые компоненты
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import ToggleLabel from "../../../Common/ToggleLabel";
import { useStore } from "../../../../store/RootStore";
import SkeletonContainer from "../../../Common/SkeletonContainer";
import LoadAuthorizedError from "../../../Common/LoadAuthorizedError";
import FormBoardsBadge from "./FormBoardsBadge";
import FormBoardsError from "./FormBoardsError";
import BoardsSkeleton from "./BoardsSkeleton";
import {
    FormBoardsStyles,
    FormBoardsLink,
    FormBoardsLinksContainer,
} from "./FormBoards.styles";
import i18nstrings from "../strings";

const FormBoards = () => {
    const store = useStore();
    const { boards } = store.formsModule;
    const {
        showForms,
        onChangeVisibleForm,
        boardsLoaded,
        boardServerError,
        isAuthorized,
    } = store.globalModule;
    const { showLoginPopup } = store.loginPopupModule;
    const {
        onSelectBoard,
        values,
        clearBoardList,
        serverBoardList,
        selectAllBoards,
        showClearButton,
        showSelectedAllButton,
    } = boards;
    const { locale } = store.localeModule;
    const strings = i18nstrings[locale || "ru"];

    const isOpen = showForms.boards;

    const handleSelected = (selectedBoards) => {
        onSelectBoard(selectedBoards);
    };

    const renderContent = () => {
        if (!isOpen) return null;

        if (!isAuthorized) {
            return (
              <SkeletonContainer>
                <LoadAuthorizedError onClick={showLoginPopup} />
              </SkeletonContainer>
            );
        }

        if (boardServerError) {
            return <FormBoardsError locale={locale} />;
        }

        if (!boardsLoaded) {
            return (
              <SkeletonContainer>
                <BoardsSkeleton />
              </SkeletonContainer>
            );
        }

        return (
          <>
            <OptionsSelector
              className="boards-selector"
              clearAllSelectedValues={clearBoardList}
              locale={locale}
              options={serverBoardList}
              popupClassName="boards-selector-popup"
              renderCheckboxContent={(board) => (
                <FormBoardsBadge
                  board={board}
                  className="bords-badge"
                  locale={locale}
                />
                    )}
              selectedValues={values.optionSelector.boardsList}
              onChange={handleSelected}
            />
            <FormBoardsLinksContainer>
              {showSelectedAllButton && (
                <FormBoardsLink>
                  <Link
                    className="board-link"
                    type="pseudo"
                    onClick={selectAllBoards}
                  >
                    {strings.selectAll}
                  </Link>
                </FormBoardsLink>
                    )}
              {showClearButton && (
                <FormBoardsLink>
                  <Link
                    className="board-link"
                    type="pseudo"
                    onClick={clearBoardList}
                  >
                    {strings.clear}
                  </Link>
                </FormBoardsLink>
                    )}
            </FormBoardsLinksContainer>
          </>
        );
    };

    const renderToggleLabel = () => {
        const labelText = boardServerError
            ? strings.boardsNotAvailable
            : strings.boards;
        return (
          <ToggleLabel
            className={boardServerError ? "red-title" : ""}
            open={isOpen}
            primary={false}
            onClick={() => onChangeVisibleForm("boards")}
          >
            {labelText}
          </ToggleLabel>
        );
    };

    return (
      <FormBoardsStyles>
        {renderToggleLabel()}
        {renderContent()}
      </FormBoardsStyles>
    );
};

export default observer(FormBoards);
