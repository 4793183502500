import styled from "styled-components";

export const MainWrapper = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        .layout {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

export const TendersWrapper = styled.div`
    margin: 0 auto 80px;
    max-width: 1180px;
    width: 100%;
    @media (max-width: 770px) {
      padding: 0 8px;
    }
    .title {
    margin-bottom: 24px;
    @media (max-width: 651px) {
      &.title{
        font-size: 22px;
        margin-bottom: 10px;
      }
    }

  }
`;
