import styled from "styled-components";

export const FilterSubscribeLinkStyles = styled.div`
  position: relative;
  .link-element {
    font-size: 12px;
  }

  .filter-info-arrow:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -27px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${(props) => props.theme.colors.color_green};
    @media (max-width: 700px) {
      display: none;
    }
  }
`;

export const FilterSubscribeLinkEnable = styled.div`
  font-size: 12px;
  .link-element {
    font-size: 12px;
  }
`;

export const FilterSubscribeLinkEnableText = styled.span`
  display: inline-block;
  margin-right: 8px;
`;
