import styled from "styled-components";

const FormExtraField = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormExtraStyles = styled.div`
  width: 228px;
  @media (max-width: 651px) {
    width: 100%;
    .form-extra-id div:nth-child(2) {
      width: 100%;
    }
  }
`;

export {
  FormExtraStyles,
  FormExtraField,
};
