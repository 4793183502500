import React from "react";
import PropTypes from "prop-types";
import Icon from "@components/icon";
import Link from "@components/link";
import {
  LoadErrorStyles,
  LoadErrorIcon,
} from "./LoadAuthorizedError.styles";
import { useStore } from "../../../store/RootStore";
import strings from "../strings";

const CarTypesError = ({ onClick }) => {
  const store = useStore();
  const { locale } = store.localeModule;
  return (
    <LoadErrorStyles>
      <LoadErrorIcon>
        <Icon
          color="var(--glz-color-neutral-tone-4)"
          fillRule="evenodd"
          height={24}
          name="ic_lock"
          viewBox="0 0 22 24"
          width={24}
        />
      </LoadErrorIcon>
      <span>{strings[locale].searchOnly}</span>
      {" "}
      <Link
        className="car-types-error-link"
        mode="pseudo"
        onClick={onClick}
      >
        {strings[locale].participants}
      </Link>
    </LoadErrorStyles>
  );
};

CarTypesError.defaultProps = {
  onClick: () => { },
};

CarTypesError.propTypes = {
  onClick: PropTypes.func,
};

export default CarTypesError;
