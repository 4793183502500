import styled from "styled-components";

export const FilterSubscribeInfoStyles = styled.div`
  background-color: ${(props) => props.theme.colors.color_green};
  color: ${(props) => props.theme.colors.color_white};
  font-size: 12px;
  padding: 4px;
  text-align: center;
  margin-bottom: -12px;
  border-radius: 4px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -7px;
    width: 0;
    right: 72px;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${(props) => props.theme.colors.color_green};
    @media (min-width: 701px) {
      display: none;
    }
  }
`;
