const en = {
  showTenders: "Show\u00A0tenders",
  subscribeToNewTendersOnATISU: "Subscribe to new tenders on ATI.SU",
  subscribeToNewTendersForThisDirection: "Subscribe to new tenders for this direction",
  subcribe: "Subscribe",
  subscribeToNewTenders: "Subscribe to new tenders",
  tendersATI: "ATI.SU tenders",
  messengerATI: "ATI Messenger",
  telegram: "Telegram",
  subscribeToTheChannel: "Subscribe to the channel with\u00A0new tenders",
  // eslint-disable-next-line max-len
  hostsSeveralTrucking: "ATI.SU hosts several trucking tenders every day. Subscribe to the channel with new tenders so you don't miss out on a major new contract for your direction.",
  channelWithNewTenders: "Channels with new tenders",
  tenderFound: ["tender found", "tenders found", "tenders found"],
  tendersNotFound: "No tenders found",
  subscribeToTenders: "Subscribe to new tenders to find out about major trucking contracts",
};

export default en;
