import React, { Fragment } from "react";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import { Emoji } from "emoji-mart";
import Link from "ati-ui-react/unstyled/components/Link"; // TODO перейти на новые компоненты
import Icon from "@components/icon";
import declension from "ati-core-utils/declension";
import Tooltip from "../../../../Common/Tooltip";
import {
  FirmListOptionStyles,
  FirmListOptionName,
  FirmListOptionEmoji,
  FirmListOptionIcon,
} from "./FirmListOption.styles";
import i18nStrings from "../../strings";

const FirmListOption = ({ option, onOpen, locale }) => {
  const strings = i18nStrings[locale || "ru"];
  const getLinkContent = () => {
    if (option.totalCount) {
      const plural = declension(option.totalCount, strings.company);
      return `${option.totalCount}\u00A0${plural}`;
    }

    return strings.noParticipants;
  };

  const renderEmoji = () => {
    if (!option.emoji) return null;
    return (
      <FirmListOptionEmoji>
        <Emoji
          backgroundImageFn={() => "//files.ati.su/assets/shared/img/emoji/sheets-128/twitter-64-v5.0.0.png"}
          emoji={option.emoji}
          size={14}
        />
      </FirmListOptionEmoji>
    );
  };

  const renderListIcon = () => {
    if (!option.description) return null;
    const id = nanoid();
    return (
      <>
        <FirmListOptionIcon data-tip data-for={`firm-option-tooltip-${id}`}>
          <Icon
            color="var(--glz-color-neutral-tone-3)"
            fillRule="evenodd"
            height={12}
            name="ic_comment-badge"
            viewBox="0 0 13 13"
            width={12}
          />
        </FirmListOptionIcon>
        <Tooltip delayShow={300} id={`firm-option-tooltip-${id}`} marginTop={-5}>
          <span>{option.description}</span>
        </Tooltip>
      </>
    );
  };

  return (
    <FirmListOptionStyles>
      <FirmListOptionName>
        {renderEmoji()}
        <span>{option.name}</span>
      </FirmListOptionName>
      <div>
        <Link gray small target="_blank" type="external" onClick={onOpen}>
          {getLinkContent()}
        </Link>
        {renderListIcon()}
      </div>
    </FirmListOptionStyles>
  );
};

FirmListOption.defaultProps = {
  option: {},
  onOpen: () => {},
};

FirmListOption.propTypes = {
  locale: PropTypes.string,
  option: PropTypes.object,
  onOpen: PropTypes.func,
};

export default FirmListOption;
