import React from "react";
import PropTypes from "prop-types";
import getEnvironDomain from "ati-utils/getEnvironDomain";
import { GridUserDataContainer, GridUserDataIframe } from "./GridUserDataStyles";
import strings from "../strings";
import { isClient } from "../../../utils/isClient";

const GridUserData = ({ showBanners, isAtiApp, locale }) => (
  <GridUserDataContainer showBanners={showBanners}>
    <div className="cl grid-user-data">

      {showBanners && !isAtiApp && (
      <>
        <div className="cl user-data-grid" min-screen-width="400">
          <span ati-userdata-info="doc-preset=allpages_300_300" />
        </div>

        <div className="cl user-data-grid" min-screen-width="400">
          <span ati-userdata-info="doc-preset=allpages_300_300;doc-extra-content=lastNews;doc-extra-content-container=bottomNewsContainer" />
        </div>
      </>
)}

      {isClient && !isAtiApp && (
      <div className="user-data-news">
        <GridUserDataIframe
          showNewsIframe
          frameBorder="0"
          src={`//news.${getEnvironDomain()}/external/last-news/?count=5&with_time=1`}
          title={strings[locale].title}
        />
      </div>
)}
    </div>
  </GridUserDataContainer>
);

GridUserData.propTypes = {
  isAtiApp: PropTypes.bool,
  locale: PropTypes.string,
  showBanners: PropTypes.bool,
};

export default GridUserData;
