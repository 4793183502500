import React from "react";
import PropTypes from "prop-types";
import {
  RowStyles,
  RowLabel,
} from "./Row.styles";

const Row = ({ children, label, className }) => (
  <RowStyles className={className}>
    {label && <RowLabel className="custom-row-label">{label}</RowLabel>}
    {children}
  </RowStyles>
);

Row.defaultProps = {
  label: "",
  className: "",
};

Row.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default Row;
